.sprite {
    background: url('/media/default/navigation.png') no-repeat top left;
}

.sprite.normalf {
    background-position: 0px 0px;
    width: 34px;
    height: 34px;
}

.sprite.normalf-active {
    background-position: 0px -44px;
    width: 34px;
    height: 34px;
}

.sprite.mediumf {
    background-position: 0px -88px;
    width: 34px;
    height: 34px;
}

.sprite.mediumf-active {
    background-position: 0px -132px;
    width: 34px;
    height: 34px;
}

.sprite.bigf {
    background-position: 0px -176px;
    width: 34px;
    height: 34px;
}

.sprite.bigf-active {
    background-position: 0px -220px;
    width: 34px;
    height: 34px;
}

.sprite.contrast {
    background-position: 0px -264px;
    width: 24px;
    height: 24px;
}

.sprite.mapa-strony {
    background-position: 0px -298px;
    width: 27px;
    height: 15px;
}

.sprite.home {
    background-position: 0px -323px;
    width: 23px;
    height: 18px;
}

.sprite.mail {
    background-position: 0px -351px;
    width: 20px;
    height: 16px;
}

.sprite.phone {
    background-position: 0px -377px;
    width: 18px;
    height: 18px;
}

.sprite.search {
    background-position: 0px -405px;
    width: 33px;
    height: 29px;
}

.hiddenLink {
    font-size: 0px;
}

.top {
    padding-top: 23px;
    padding-bottom: 30px;
    background: $white;

    @media (max-width:$screen-sm - 1px) {
        display: none;
    }

    &__container {
        padding: 0;
    }

    &__img {
        position: relative;
        left: 20px;
        margin: 0 auto;

        @media (max-width:$screen-md - 1px) {
            position: static;
        }
    }
}

.fund {
    width: 80%;
    margin: 0 auto;

    @media (max-width:$screen-lg - 1px) {
        width: 100%;
    }

    &__text {
        max-width: 565px;
        color: $color23;
        text-align: center;
        font-family: "Tahoma";
        line-height: 120%;
        float: left;
        padding: 0 5px;
        font-size: 0.8em !important;

        @media (max-width:$screen-md - 1px) {
            max-width: 465px;
        }

        &--bold {
            font-weight: bold;
        }
    }

    &__img {
        max-width: 19%;
        height: auto;
        float: left;
    }
}


.beam {
    background: $color1;
    min-height: 51px;

    @media (max-width:$screen-sm - 1px) {
        min-height: 100px;
    }

    &__container {
        padding: 0;

    }

    &__center-col {
        position: relative;
    }

    &__right-col {
        position: relative;
    }
}

.wcag {
    position: relative;
    top: 16px;

    @media (max-width:$screen-sm - 1px) {
        max-width: 300px;
        max-width: 220px;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    &__contrast {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -264px;
        width: 24px;
        height: 24px;
        width: 24px;
        height: 24px;
        float: left;
        margin-left: 4px;
    }

    &__map {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -298px;
        width: 27px;
        height: 15px;
        width: 27px;
        height: 15px;
        float: left;
        margin-left: 15px;
        position: relative;
        top: 5px;

    }

    &__normalf {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px 0px;
        width: 34px;
        height: 34px;
        width: 34px;
        height: 34px;
        float: left;
        margin-left: 31px;
        position: relative;
        top: -5px;

        &--active {
            background-position: 0px -44px;
        }

    }

    &__mediumf {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -88px;
        width: 34px;
        height: 34px;
        width: 34px;
        height: 34px;
        float: left;
        margin-left: 2px;
        position: relative;
        top: -5px;
        margin-left: 6px;

        &--active {
            background-position: 0px -132px;
        }

    }

    &__bigf {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -176px;
        width: 34px;
        height: 34px;
        width: 34px;
        height: 34px;
        float: left;
        margin-left: 2px;
        position: relative;
        top: -5px;
        margin-left: 6px;

        &--active {
            background-position: 0px -220px;
        }
    }
}


.navigation {
    width: 100px;
    position: absolute;
    right: 185px;
    top: 18px;

    @media (max-width:$screen-md - 1px) {
        right: 130px;
    }

    @media (max-width:$screen-sm - 1px) {
        position: static;
        margin-top: 18px;
        margin-left: 20px;
    }


    &__home {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -323px;
        width: 23px;
        height: 18px;
        width: 23px;
        height: 18px;
        float: left;
    }

    &__mail {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -351px;
        width: 20px;
        height: 16px;
        width: 20px;
        height: 16px;
        float: left;
        position: relative;
        margin-left: 20px;
        top: 2px;
    }

    &__phone {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -377px;
        width: 18px;
        height: 18px;
        width: 18px;
        height: 18px;
        float: left;
        position: relative;
        margin-left: 18px;
        top: 1px;
    }
}

.language {
    position: absolute;
    right: 86px;
    top: 19px;

    &__link {
        text-transform: uppercase;
        float: left;
        font-size: 0.750em;
        color: $white;
        padding: 0 10px;
        border-right: 1px solid $white;

        &:last-child {
            border: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $white;
        }
    }
}

.search {
    position: absolute;
    right: 27px;
    top: 12px;
    padding-left: 17px;
    border-left: 1px solid $white;

    &__button {
        background: url('/media/default/navigation.png') no-repeat top left;
        background-position: 0px -377px;
        width: 18px;
        height: 18px;
        width: 18px;
        height: 18px;
        background-position: 0px -405px;
        width: 33px;
        height: 29px;
        display: block;
    }
}

.search-block {
    background: $color1;
    padding: 100px;
    display: block;
    padding: 50px;
    position: relative;
    z-index: 100;
    display: none;

    @media (max-width:$screen-md - 1px) {
        padding: 30px;
    }

    @media (max-width:$screen-sm - 1px) {
        padding: 30px 0;
    }





    &__form {
        max-width: 800px;
        margin: 0 auto;
        position: relative;

        @media (max-width:$screen-md - 1px) {
            max-width: 80%;
        }
    }

    &__input {
        width: 100%;
        height: 53px;
        line-height: 53px;
        background: $white;
        font-size: 1em;
        color: $color1;
        margin-bottom: 30px;
        padding-left: 10px;
        line-height: 53px;

        @media (max-width:$screen-sm - 1px) {
            position: relative;

        }



        &::placeholder {
            color: $color1;
            font-style: italic;
            font-size: 1em;
        }



        &:focus {
            outline: none;
        }

        @media (max-width:$screen-md - 1px) {
            height: 47px;
            border: 0;
            padding-right: 50px;
            line-height: 47px;
        }


    }

    &__button {
        display: block;
        width: 210px;
        background: transparent;
        border: 2px solid $color2;
        margin: 0 auto;
        font-size: 1em;
        color: $color2;
        margin-top: 10px;
        padding: 18px 0;
        font-weight: 600;
        text-transform: uppercase;
        outline: 0;
        transition: background 0.3s;
        -webkit-transition: background 0.3s;
        -moz-transition: background 0.3s;
        -ms-transition: background 0.3s;
        -o-transition: background 0.3s;

        @media (max-width:$screen-sm - 1px) {
            font-size: 0.85em;
            padding: 12px 0;
            width: 180px;
            margin-top: 0;
        }



        &:hover,
        &:focus {
            background: $color2;
            color: $color1;
            font-weight: 600;

        }
    }

    &__close {
        width: 50px;
        height: 50px;
        display: block;
        cursor: pointer;
        position: absolute;
        right: -100px;
        top: -10px;
        font-size: 0;

        @media (max-width:$screen-md - 1px) {
            right: -60px;
        }

        @media (max-width:$screen-sm - 1px) {
            width: 25px;
            height: 25px;
            right: -30px;
        }

    }

    &__span {
        display: block;
        width: 50px;
        height: 5px;
        margin-bottom: 4px;
        background: $color2;

        @media (max-width:$screen-sm - 1px) {
            width: 25px;
            height: 3px;
        }

        &--ft {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            position: relative;
            top: 24px;

            @media (max-width:$screen-sm - 1px) {
                top: 21px;
            }
        }

        &--sd {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            top: 14px;
            position: relative;

        }
    }
}







.header {
    background: $color2;
    padding: 23px 0;
    padding-bottom: 11px;

    @media (max-width:$screen-md - 1px) {
        padding-bottom: 0;
    }

    @media (max-width:$screen-sm - 1px) {
        padding: 15px 8px;
    }

    &__container {
        padding: 0;
        position: relative;
    }

    &__img {
        float: left;
        position: relative;
        z-index: 20;

        @media (max-width:$screen-md - 1px) {
            max-width: 150px;
        }

        @media (max-width:$screen-sm - 1px) {
            max-width: 120px;
        }

    }

    .header-contact {
        float: right;
        margin-top: -5px;
        margin-right: 40px;
        margin-bottom: 40px;

        @media (max-width:$screen-sm - 1px) {
            margin-bottom: 0;
            max-width: 120px;
            margin-right: 25px;
        }


        &__text {
            color: $color3;
            font-size: 0.938em;
            line-height: 120%;

            @media (max-width:$screen-sm - 1px) {
                font-size: 12px;
            }

            &--hours {
                font-size: 0.750em;
                margin-top: 15px;

                @media (max-width:$screen-sm - 1px) {
                    margin-top: 5px;
                    font-size: 12px;
                }
            }
        }

        &__link {
            color: $color3;

            &:hover,
            &:focus {
                text-decoration: none;
                color: $color3;
            }
        }
    }
}