.gallery {
    background: url('/media/default/galleryBg.jpg')no-repeat;
    background-size: cover;
    padding: 30px 0;
    padding-bottom: 40px;

    @media (max-width:$screen-sm - 1px) {
        padding-bottom: 35px;
    }

    &__pause {
        margin-top: 20px;
        text-transform: uppercase;
        color: #000;
        font-weight: bold;
        display: inline-block;

        &:hover {
            color: inherit;

            &:focus {
                outline: 2px solid #0000ff;
                color: inherit;
            }
        }
    }


    &__container {
        padding: 0;

        @media (max-width:$screen-md - 1px) {
            padding: 0 30px;
        }

        @media (max-width:$screen-sm - 1px) {
            padding: 0 15px;
            max-width: 320px;

        }
    }

    &__header {
        font-size: 2.813em;
        color: $color10;
        text-transform: uppercase;
        line-height: 120%;
        margin: 0;
        letter-spacing: 0.7px;
        width: 525px;
        padding-bottom: 8px;
        border-bottom: 2px solid $color11;

        &.otherColor {
            border-color: $color5;
        }

        @media (max-width:$screen-sm - 1px) {
            width: 292px;
            font-size: 1.875em;
        }

    }

    .gallery-carousel {
        max-width: 1080px;
        margin: 0 auto;
        margin-top: 46px;
        position: relative;

        @media (max-width:$screen-md - 1px) {
            max-width: 540px;
        }

        @media (max-width:$screen-sm - 1px) {
            max-width: 233px;
            margin-top: 25px;
        }
    }

    .gallery-box {
        position: relative;
        display: block;
        background: linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.75));

        &__img {
            position: relative;
            z-index: -1;
        }

    }

    .gallery-desc {
        position: absolute;
        width: 100%;
        bottom: 10px;
        right: 0;
        left: 0;
        margin: auto;
        width: 230px;

        @media (max-width:$screen-sm - 1px) {
            width: 196px;
        }

        &__title {
            font-size: 1.250em;
            color: $white;
            font-weight: bold;
            line-height: 120%;
            margin: 0;

            @media (max-width:$screen-sm - 1px) {
                font-size: 1em;
            }
        }

        &__date {
            font-size: 0.813em;
            color: $white;
            line-height: 120%;
            margin-top: 5px;

            @media (max-width:$screen-sm - 1px) {
                font-size: 0.78em;
            }
        }
    }

    .gallery-block {
        display: none;

        &.active {
            display: block;
        }
    }
}

.owl-nav {
    width: 100%;
    margin-top: -150px;
    margin-bottom: 190px;

}

.owl-prev {
    width: 40px;
    height: 40px;
    background: url('/media/default/gallery-arrow-prev.png')no-repeat;
    display: block;
    position: absolute;
    left: -45px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;

    @media (max-width:$screen-sm - 1px) {
        transform: scale(0.9);
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        left: -42px;
    }

    &:hover {
        opacity: 0.75;
    }

}

.owl-next {
    width: 40px;
    height: 40px;
    background: url('/media/default/gallery-arrow-next.png')no-repeat;
    display: block;
    position: absolute;
    right: -45px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;

    @media (max-width:$screen-sm - 1px) {
        transform: scale(0.9);
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        right: -42px;
    }

    &:hover {
        opacity: 0.75;
    }

}



.gallery-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .menu-tabs {
        &__li {
            padding: 20px 0;
            border-bottom: 1px dashed $color8;



        }

        &__a {
            font-size: 1.25em;
            display: block;
            white-space: normal;

            &:hover,
            &:focus {
                color: $black !important;
                text-decoration: none;

            }
        }
    }

    .dropdown-menu {
        position: static;
        margin: 0;
        padding: 0;
        float: none;
        background: none !important;
        box-shadow: none;
        border: 0;
        font-size: 1.025em;

        .menu-tabs {
            &__li {
                border: 0;
                padding-bottom: 0;
            }

            &__a {
                color: $color10;

                &:hover,
                &:focus {
                    background: none;
                    color: $black;
                }
            }
        }

    }
}

.dropdown-backdrop {
    display: none !important;
}