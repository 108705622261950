.form{
	&__label{
		font-size:1em;
		color:$color10;
	}

	&__input{
		margin-bottom:10px;
		font-size:1em;
		border:1px solid $color19;
			&:focus{
				box-shadow:none;
				border-color:$color19;
			}
	}

	&__textarea{
		margin-bottom:10px;
		font-size:1em;
		border:1px solid $color19;	
			&:focus{
				box-shadow:none;
				border-color:$color19;
			}
	}

	&__button{
		font-size:0.875em;
		color:$white;
		text-align: center;
		font-weight: bold;
		border:1px solid $white;
		background:$color4;
		width:100px;
		padding:10px 0;
	   	transition:background 0.3s, color 0.3s;
	   	-webkit-transition:background 0.3s, color 0.3s;
	   	-moz-transition:background 0.3s, color 0.3s;
	   	-ms-transition:background 0.3s, color 0.3s;
	   	-o-transition:background 0.3s, color 0.3s;
	   	margin-right:20px;
	   	outline:0;
			&:hover{
			    text-decoration: none;
			    background:$white;
			    color:$color4;
			   	border-color:$color4;
			   
			}

	}
}

.alert{
	font-size:1em;
	
}