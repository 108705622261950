.gatherings{

	&__link{
		font-weight:bold;
		color:$color4 !important;
		padding:10px 0;
		display:block;
		border-bottom:1px solid $color3;
		font-size:1.2em;
		&:hover,&:focus{
			text-decoration: none;
			color:$color4;
		}
	}

	.gatherings-block{
		padding:10px 0;
		border-bottom:1px dashed $color3;
		margin-bottom:20px;

				&__title{
					font-size:1.2em;
					margin:0;
					font-weight:bold;
						
				}

				&__date{
					font-size:0.8em;
					font-style:italic;
					text-align: right;
					margin:10px 0;
				}
				
				&__desc{
					font-style:italic;
				}

				&__button{
					color:$color4;
					display:block;
					margin:10px;
					font-style:italic;
					text-align: right;
					outline:0;

					&:hover,&:focus{
						color:$color4;

					}
				}
	}
}