.competitions{
	padding-top:10px;
	width:250px;
	background:$white;
	position:relative;
	float:right;

	@media (max-width:$screen-md - 1px){
		float:none;
	}

	&::before{
    display: block;
    content: "";
    height: 100%;
    width: 100vw;
    background: $white;
    position: absolute;
    left: -100vw;
    top: 0;
	}


	&__title{
		font-size:2.063em;
		max-width:250px;
		color:$white;
		font-weight: 700;
		background:$color6;
		text-transform: uppercase;
		padding: 29px 8px;
    	letter-spacing: 1px;

    			&--offers{
    				color:$color3;
    				padding:0;
    				max-width: 100%;
    				background:none;
    				line-height: 120%;
    				font-size:1.2em;
    				background:none !important;
    				margin-top:30px;
    			}

	}

	.competitions-list{
		padding:0;
		margin:0;
		list-style-type:none;

			&--offers{
				margin-top:20px;

			}


	&__li{
			border-bottom:1px solid $color8;

			&:last-child{
				border:0;
			}

					&--offers{
						&:first-child{
							border-top:1px solid $color8 !important;
						}
						
						&:last-child{
							border-bottom:1px solid $color8 !important;
						}
					}

					&.active{
						.competitions-list__a{
							color:$color6;
						}
					}


		}

		&__a{
			color:$color3;
    		font-size: 0.813em;
    		padding: 16px 0;
    		display: block;
    		max-width: 230px;
    		line-height: 120%;
    		font-weight: 600;

    				&--big{
    					font-size:1.2em !important;
    					text-transform: uppercase;
    					margin-bottom:5px;
    				}
    		


    		&:hover,&:focus{
    			color:$color6;
    			text-decoration: none;
    		}
		}



		.menu-tabs__li{
			border-bottom:1px solid $color8;

			&:last-child{
				border:0;
			}

					&--offers{
						&:first-child{
							border-top:1px solid $color8;
						}
						
						&:last-child{
							border-bottom:1px solid $color8;
						}
					}

					&.active{
						.menu-tabs__a{
							color:$color6;
						}

						.dropdown-menu{
							.menu-tabs{
								
								&__li{
									&.active{
										.menu-tabs__a{
											color:$color6;
										}
									}
								}

								&__a{
									color:$color3;
								}
							}
						}
					}
		}

		.menu-tabs__a{
			color:$color3;
    		font-size: 0.813em;
    		padding: 16px 0;
    		display: block;
    		max-width: 230px;
    		line-height: 120%;
    		font-weight: 600;
    		


    		&:hover,&:focus{
    			color:$color6;
    			text-decoration: none;
    		}
		}
	}
}
