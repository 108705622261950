.logos {
    background: $white;
    padding: 45px;
    padding-bottom: 0;

    @media (max-width:$screen-sm - 1px) {
        padding: 10px;
    }

    &__pause {
        margin: 20px;
        text-transform: uppercase;
        color: #000;
        font-weight: bold;
        display: inline-block;

        &:hover {
            color: inherit;

            &:focus {
                outline: 2px solid #0000ff;
                color: inherit;
            }
        }
    }


    &__container {
        padding: 0;

        @media (max-width:$screen-md - 1px) {
            padding: 0 30px;
            width: auto;
            max-width: 768px;
        }

        @media (max-width:$screen-sm - 1px) {
            padding: 0;
            max-width: 320px;
        }

    }

    .logos-carousel {
        max-width: 1150px;
        margin: 0 auto;
        padding-bottom: 75px;
        border-bottom: 1px solid $color14;

        @media (max-width:$screen-md - 1px) {
            padding-bottom: 40px;
        }


        @media (max-width:$screen-sm - 1px) {
            padding-bottom: 20px;
            padding-top: 10px;
        }
    }


    .logos-bottom {
        margin-top: 25px;
        padding-bottom: 45px;
        border-bottom: 1px solid $color14;

        @media (max-width:$screen-md - 1px) {
            padding-bottom: 30px;
            border-bottom: 0;
        }

        @media (max-width:$screen-sm - 1px) {
            padding-bottom: 10px;
        }

        &__row {

            margin: 0 auto;
        }


        &__img {
            margin: 0 auto;
            max-height: 78px;


            @media (max-width:$screen-sm - 1px) {
                margin-bottom: 10px;
            }
        }
    }
}