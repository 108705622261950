.projectsPage{
	display:block;
	margin-bottom:30px;
	padding-bottom:30px;
	border-bottom:1px solid $color8;
	&__link{
		display:block;
		font-size:1.25em;
		margin-bottom:30px;
		line-height:120%;

		@media (max-width:$screen-sm){
			font-size: 1.1em;
			margin-bottom:15px;
		}

		&:hover,&:focus{
			text-decoration: none;
		}
	}

	&__img{
		max-width:440px;

		@media (max-width:$screen-sm - 1px){
			max-width: 100%;
		}
	}
}