.fbWidget{
	width:392px;
	position:fixed;
	right:-341px;
	z-index:100;
	top:450px;
	cursor:pointer;
	transition:right 0.3s;

	@media (max-width:$screen-sm - 1px){
		display:none;
	}

	

	@media (max-width:$screen-lg - 1px){
		top:330px;

		span{
			height:400px !important;
		}
		iframe{
			height:400px !important;
		}
	}

	@media (max-width:1299px){
		top:400px;
	}

		&:hover{
			right:0;
		}

	&__button{
		width:51px;
		height:208px;
		background:url('/media/default/fb-button.png')no-repeat;
		float:left;

		@media (max-width:1299px){
			transform: scale(0.74);
			-webkit-transform: scale(0.74);
			-moz-transform: scale(0.74);
			-ms-transform: scale(0.74);
			-o-transform: scale(0.74);
			position: relative;
    		top: -27px;
    		right: -7px;
		}
	}

	&__container{
		float:left;
		border:1px solid $color17;
		border-right:0;
	}
}

.fb-link{
	display:none;

	@media (max-width:$screen-sm - 1px){
		display:block;
		margin:5px auto;
		width:300px;
	}
}