.promotion{
	margin-top:20px;


	&__header{
		font-size:1.2em;
	}

	&__ul{
		list-style-type:none;
		margin:0;
		padding:0;
		margin-top:15px;
	}

	&__li{
		line-height: 120%;
		padding:15px 0;
		border-bottom:1px solid $color3;

				&.active{
					border-bottom:0;
					.promotion-logos{
						display:block;
						border-top:1px solid $color3;
						padding-top:15px;
						margin-bottom:0;
					}
				}
	}

	&__a{
		font-size:1.1em;

		&:hover{
			text-decoration: none;
			font-weight: bold;
		}

		&:focus{
			text-decoration: none;
		}
	}

	.promotion-logos{
		margin:15px 0;
		display:none;

		&__text-link{
			display:block;
			margin-bottom:15px;
			font-size:0.9em;
		}

		&__text{
			font-weight:bold;
			font-size:1em;
			margin-bottom:5px;
		}


		&__link{
			display:inline-block;
			margin:10px;

			@media (max-width:$screen-sm - 1px){
				margin:5px;
			}
		}

		&__img{
			@media (max-width:$screen-sm - 1px){
				max-height:80px;
			}
		}
	}
}