.menu {
    position: relative;
    z-index: 10;

    @media (max-width:$screen-md - 1px) {
        display: none;
    }

    background:$color2;

    &__container {
        padding: 0;
    }

    .menu-list {
        float: right;

        &__name {
            text-transform: uppercase;
            color: $white;
            width: 378px;
            text-align: center;
            padding: 11px 0;
            padding-bottom: 15px;
            display: inline-block;
            font-size: 1.063em;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &--first {
                background: $color4;
            }

            &--second {
                background: $color5;
                margin-left: 7px;
            }
        }
    }

    .menu-tabs {


        &--first {
            background: $color4;

        }

        &--second {
            background: $color5;

        }

        &.menu-tabs-active {
            display: block;
        }

        &__container {
            max-width: 73.125em;
            margin: 0 auto;
        }

        &__ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            @media (max-width:$screen-lg - 1px) {
                padding: 0 10px;
            }
        }

        &__li {
            float: left;
            margin: 23px 0;

            &:first-child {
                .menu-tabs__a {
                    padding-left: 0;
                }
            }

            &:last-child {
                .menu-tabs__a {
                    border: 0;
                    padding-right: 0;
                }
            }

            &.active {
                .menu-tabs__a {
                    color: $black;
                }

                a {
                    color: $black;
                }


            }

            a {
                display: block;
                color: $white;
                font-size: 0.813em;
                text-transform: uppercase;
                padding: 0 20px;
                padding-right: 23px;
                border-right: 1px solid $white;
                transition: color 0.2s;
                -webkit-transition: color 0.2s;
                -moz-transition: color 0.2s;
                -ms-transition: color 0.2s;
                -o-transition: color 0.2s;

                @media (max-width:$screen-lg - 1px) {
                    font-size: 0.750em;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $black;
                }
            }


        }

        &__a {
            display: block;
            color: $white;
            font-size: 0.813em;
            text-transform: uppercase;
            padding: 0 20px;
            padding-right: 23px;
            border-right: 1px solid $white;
            transition: color 0.2s;
            -webkit-transition: color 0.2s;
            -moz-transition: color 0.2s;
            -ms-transition: color 0.2s;
            -o-transition: color 0.2s;

            @media (max-width:$screen-lg - 1px) {
                font-size: 0.750em;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                color: $black;
            }


        }
    }
}

.dropdown-menu {
    position: static;
    width: 100%;
    box-shadow: none;
    border: 0;
    font-size: 1em;
    margin-bottom: 20px;
    border-bottom: 1px solid $color8;
    padding-left: 15px;

    @media (max-width:$screen-md - 1px) {
        background: $color6 !important;
        border: 0;
        color: $white !important;
        padding: 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    }


    .menu-tabs__a {
        text-transform: uppercase;

        &:hover,
        &:focus {
            background: none;
        }

        @media (max-width:$screen-md - 1px) {
            border: 0 !important;
            color: $white;
        }
    }



    @media (max-width:$screen-md - 1px) {
        float: none;
        border-top: 0.5px solid rgba(255, 255, 255, 0.25);
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
        margin: 0;
    }

}

.mobile-menu {
    display: none;

    @media (max-width:$screen-md - 1px) {
        display: block;
    }

    &__ul {
        padding: 0;
        margin: 0;
        list-style-type: 0;

        &--first {
            background: $color4;
        }

        &--second {
            background: $color5;
        }

        &--competitions {
            background: $color6;
        }

    }

    &__li {
        &.activeMenu {
            .dropdown {
                display: block;
            }


        }
    }

    &__a {
        display: block;
        font-size: 1.063em;
        color: $white;
        font-weight: 400;
        text-align: center;
        padding: 15px 10px;
        text-transform: uppercase;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $white;
        }
    }

    .menu-tabs {
        &__li {
            &:first-child {
                .menu-tabs__a {
                    border-top: 1px solid $white;
                }


            }

            a {
                display: block;
                text-align: center;
                font-size: 0.813em;
                text-transform: uppercase;
                padding: 15px 10px;
                border-top: 0.5px solid rgba(255, 255, 255, 0.25);
                border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $white;
                }
            }
        }

        &__a {
            display: block;
            text-align: center;
            font-size: 0.813em;
            text-transform: uppercase;
            padding: 15px 0;
            border-top: 0.5px solid rgba(255, 255, 255, 0.25);
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);

            &:hover,
            &:focus {
                text-decoration: none;
                color: $white;
            }
        }


    }

    .menu-tabs__li.active {

        .menu-tabs__a,
        a {
            color: $black;
        }
    }


    .competitions-list {
        padding: 0;
        margin: 0;
        list-style-type: 0;

        &__li {
            &:first-child {
                .competitions-list__a {
                    border-top: 1px solid $white;
                }
            }
        }

        &__a {
            display: block;
            text-align: center;
            font-size: 0.813em;
            text-transform: uppercase;
            padding: 15px 0;
            border-top: 0.5px solid rgba(255, 255, 255, 0.25);
            border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);

            &:hover,
            &:focus {
                text-decoration: none;
                color: $white;
            }
        }
    }

    .dropdown {
        display: none;
    }

}

.dropdown.menu-tabs__li.active.open {
    .dropdown-menu {
        .menu-tabs__a {
            color: $color3;

            @media (max-width:$screen-md - 1px) {
                color: $white;
            }
        }

        .menu-tabs__li.active {
            .menu-tabs__a {
                color: $color22;

                @media (max-width:$screen-md - 1px) {
                    color: $black;
                    background: none;
                }
            }
        }
    }
}