a {
  color: #000; }

.gallery__pause:focus,
.logos__pause:focus {
  outline: 2px solid #0000ff !important;
  color: #000 !important; }

[role="tabpanel"]:focus {
  outline: 2px solid #ff0 !important;
  outline-offset: -4px !important; }

.menu-tabs__a:hover, .menu-tabs__a:focus,
.menu-tabs a:hover,
.menu-tabs a:focus {
  color: #fff !important; }

.menu-tabs__li.active .menu-tabs__a,
.menu-tabs__li.active a {
  color: #fff !important; }

.footer__link {
  color: #fff !important; }

.header a {
  outline-color: #000 !important; }

.menu-list__name {
  outline-offset: -4px !important; }

.hidden-element {
  color: #fff !important;
  background: #000 !important; }

.mobile-menu a {
  color: #fff !important;
  background: #000 !important; }

.menu-tabs__a,
.page-baner-link,
.file > a,
.breadcrumbs a,
.news-list {
  outline-color: #0000FF !important; }

.chooseCommune-list__select {
  outline-color: #0000FF !important; }

.menu-tabs__li > a {
  outline-color: #0000FF !important; }

.news {
  background: #000 !important;
  border-top: 2px solid #fff; }

.button {
  transition: none !important; }

.form__button {
  transition: none !important; }

.projects .projects-map .map-block__city {
  transition: none !important; }

.hr {
  border-color: #000 !important; }

.gallery {
  background: #fff !important;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  border-right: 0.5px solid #000; }

.pagination a {
  outline-color: #0000ff !important; }

.gallery-box {
  outline-color: #0000ff !important;
  outline-offset: -2px !important; }

.logos-bottom a {
  outline-color: #0000ff !important;
  display: block; }

.page-gallery__link,
.page-result-link {
  outline-color: #0000ff !important; }

.gatherings-block__button {
  outline: 2px solid;
  outline-color: #0000ff !important; }

.button,
.information-button {
  outline-color: #0000ff !important; }

.gatherings a {
  outline-color: #0000ff !important; }

.page__column--left {
  border-right: 2px solid #000; }

.mobile-menu__ul {
  border-bottom: 1px solid #fff; }
  .mobile-menu__ul:last-child {
    border: 0; }

.mobile-menu .menu-tabs__li a {
  border-color: #fff !important; }

.alert {
  background: #fff;
  border: 1px solid #000;
  color: #000; }

.questionnaire {
  border: 1px solid #fff; }

@media (max-width: 1199px) {
  .dropdown-menu {
    background: #000 !important;
    border-color: #fff !important; } }

.sitemap__ul .menu-tabs__a:hover, .sitemap__ul .menu-tabs__a:focus {
  color: #000 !important; }

html,
body {
  font-size: 1em;
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
  line-height: 120%;
  background: #000;
  overflow-x: hidden; }
  html a:focus,
  html button:focus,
  html select:focus,
  html input:focus,
  body a:focus,
  body button:focus,
  body select:focus,
  body input:focus {
    outline: 2px solid #ff0;
    outline-offset: 2px; }
  html *[role="button"]:focus,
  body *[role="button"]:focus {
    outline: 2px solid #ff0;
    outline-offset: 2px; }
  html.using-mouse a:focus,
  html.using-mouse button:focus,
  body.using-mouse a:focus,
  body.using-mouse button:focus {
    outline: 0 !important; }
  html.using-mouse *[role="button"]:focus,
  body.using-mouse *[role="button"]:focus {
    outline: 0 !important; }

.hidden-element {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.normal_f {
  font-size: 1em; }

.medium_f {
  font-size: 1.125em; }

.big_f {
  font-size: 1.25em; }

.clear {
  clear: both; }

.content {
  position: relative;
  top: -45px;
  background: #fff; }
  @media (max-width: 1199px) {
    .content {
      position: static; } }

.hr,
hr {
  border: 0.5px solid rgba(136, 136, 136, 0.29);
  margin-top: 35px;
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .hr,
    hr {
      margin-top: 25px;
      margin-bottom: 20px; } }

h1 {
  font-size: 2.813em; }

h2 {
  font-size: 2.063em; }

h3 {
  font-size: 1.250em; }

h4 {
  font-size: 1.125em; }

h5 {
  font-size: 1em; }

h6 {
  font-size: 0.875em; }

.button {
  display: block;
  font-size: 0.875em;
  color: #fff;
  text-align: center;
  font-weight: bold;
  border: 1px solid #fff;
  background: #000;
  max-width: 267px;
  padding: 20px 0;
  margin: 0 auto;
  position: relative;
  left: -142px;
  margin-top: 35px;
  transition: background 0.3s, color 0.3s;
  -webkit-transition: background 0.3s, color 0.3s;
  -moz-transition: background 0.3s, color 0.3s;
  -ms-transition: background 0.3s, color 0.3s;
  -o-transition: background 0.3s, color 0.3s; }
  .button.otherColor {
    background: #000 !important; }
    .button.otherColor:hover, .button.otherColor:focus {
      color: #000 !important;
      border-color: #000 !important;
      background: #fff !important; }
  @media (max-width: 1399px) {
    .button {
      position: static; } }
  @media (max-width: 1199px) {
    .button {
      margin-top: 15px; } }
  @media (max-width: 767px) {
    .button {
      margin-top: 35px; } }
  .button--gallery {
    position: static;
    margin-top: 45px; }
    @media (max-width: 1199px) {
      .button--gallery {
        margin-top: 20px; } }
  .button--projects {
    position: static;
    margin-top: 20px; }
  .button:hover, .button:focus {
    text-decoration: none;
    background: #fff;
    color: #000;
    border-color: #000; }

.information-button {
  display: block;
  font-size: 1.313em;
  color: #fff;
  text-align: center;
  background: #000;
  padding: 17px 0;
  width: 766px;
  margin: 20px auto;
  line-height: 120%;
  margin-bottom: 45px; }
  @media (max-width: 1199px) {
    .information-button {
      display: none; } }
  .information-button:hover, .information-button:focus {
    text-decoration: none;
    color: #fff; }
  .information-button__arrow {
    display: inline-block;
    width: 12px;
    height: 13px;
    background: url("/media/default/button-arrow.png") no-repeat;
    position: relative;
    left: 5px; }

.pagination .disabled {
  display: none; }

.pagination span:hover, .pagination span:focus {
  background: #000 !important; }

.pagination .active span {
  background: #000;
  font-size: 1em;
  display: block;
  padding: 5px 15px; }

.pagination .active a {
  padding: 5px 12px !important; }

.pagination a {
  font-size: 1em;
  color: #000 !important !important;
  padding: 5px 12px !important; }
  .pagination a:hover, .pagination a:focus {
    background: #fff !important; }

.skipLinks {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; }
  .skipLinks__link {
    display: block;
    font-size: 1.5em;
    color: #ff0;
    background: #000;
    text-decoration: none;
    padding: 20px;
    text-align: center;
    width: 300px;
    position: absolute;
    left: -999em; }
    .skipLinks__link:focus {
      text-decoration: none;
      color: #ff0;
      left: 0; }

table {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
  display: block; }
  table tr td {
    border: 1px solid #000 !important;
    padding: 5px; }

.arrows {
  display: inline-block;
  background: url("/media/default/arrows.png") no-repeat;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  position: relative;
  top: 1px; }

.questionnaire {
  display: block;
  background: #000;
  color: #fff;
  font-size: 24px;
  padding: 15px 20px;
  padding-bottom: 25px;
  border-radius: 5px;
  position: fixed;
  font-weight: bold;
  right: -95px;
  top: 300px;
  z-index: 100;
  transform: rotate(-90deg); }
  @media (max-width: 1399px) {
    .questionnaire {
      top: 180px; } }
  @media (max-width: 1299px) {
    .questionnaire {
      font-size: 20px;
      right: -85px;
      top: 275px;
      padding: 10px 20px;
      padding-bottom: 15px; } }
  @media (max-width: 767px) {
    .questionnaire {
      position: static;
      transform: none;
      max-width: 300px;
      margin: 5px auto;
      border-radius: 0;
      text-align: center; } }
  .questionnaire:hover, .questionnaire:focus {
    text-decoration: none;
    color: #fff; }

.list__ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.list__li {
  border-bottom: 1px solid #000;
  padding: 15px 0;
  margin-bottom: 15px; }
  .list__li.active .list__content {
    display: block; }

.list__a {
  font-size: 1.1em; }
  .list__a:hover, .list__a:focus {
    text-decoration: none; }
  .list__a:hover {
    font-weight: bold; }

.list__content {
  display: none;
  margin-top: 15px; }
  .list__content a:hover, .list__content a:focus {
    text-decoration: none; }

.sitemap__ul {
  list-style-type: none;
  font-size: 1.3em; }
  @media (max-width: 1199px) {
    .sitemap__ul {
      margin: 0;
      padding: 0; } }
  .sitemap__ul li {
    font-weight: bold; }
  @media (max-width: 1199px) {
    .sitemap__ul--next {
      margin-left: 30px; } }
  .sitemap__ul--next li {
    font-weight: 400; }
  .sitemap__ul--next a {
    font-size: 0.7em; }

.sitemap__li {
  line-height: 120%;
  margin-bottom: 10px; }

.sitemap .dropdown-menu {
  background: none !important;
  margin: 0;
  border: 0;
  color: #000 !important;
  font-size: 1em;
  display: block; }
  .sitemap .dropdown-menu .menu-tabs__a {
    color: #000 !important; }
    .sitemap .dropdown-menu .menu-tabs__a:hover {
      text-decoration: underline; }

.breadcrumbs {
  padding: 15px 35px;
  padding-bottom: 0;
  margin-bottom: -20px; }
  @media (max-width: 767px) {
    .breadcrumbs {
      padding: 15px 15px;
      padding-bottom: 0;
      margin-bottom: 0; } }
  .breadcrumbs ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; }
  .breadcrumbs li {
    display: flex;
    align-items: center; }
    .breadcrumbs li:last-child span {
      display: none; }
    .breadcrumbs li:last-child a {
      font-weight: bold; }
    .breadcrumbs li span {
      margin: 0 10px; }
  .breadcrumbs a {
    color: #333;
    font-size: 0.8em;
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    cursor: default; }
    .breadcrumbs a:hover:not([href="#"]) {
      font-weight: bold;
      cursor: pointer; }
    .breadcrumbs a::first-letter {
      text-transform: uppercase; }

.page {
  background: #fff;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex; }
  @media (max-width: 1199px) {
    .page {
      display: block; } }
  .page__column {
    float: left; }
    @media (max-width: 1199px) {
      .page__column {
        float: none; } }
    .page__column--left {
      width: 32.33%;
      padding: 0 15px;
      padding-bottom: 30px; }
      @media (max-width: 1499px) {
        .page__column--left {
          width: auto; } }
      @media (max-width: 1199px) {
        .page__column--left {
          width: 100%;
          display: none; } }
    .page__column--right {
      width: 67.6666666667%;
      background: #fff; }
      @media (max-width: 1499px) {
        .page__column--right {
          width: 100%; } }
      @media (max-width: 1199px) {
        .page__column--right {
          width: 100%; } }

.page-baner-link {
  display: block;
  margin-top: 30px; }

.subpage {
  padding: 36px 35px;
  padding-bottom: 52px; }
  @media (max-width: 767px) {
    .subpage {
      padding: 20px 15px; } }
  .subpage .subpage-container {
    max-width: 870px; }
    @media (max-width: 767px) {
      .subpage .subpage-container--news-list {
        max-width: 320px;
        margin: 0 auto; } }
  .subpage__title {
    color: #000;
    font-size: 2.813em;
    line-height: 120%;
    margin: 0;
    padding: 0;
    width: 525px;
    padding-bottom: 8px;
    border-bottom: 2px solid #000;
    margin-bottom: 40px; }
    .subpage__title.otherColor {
      border-color: #000 !important; }
    @media (max-width: 767px) {
      .subpage__title {
        font-size: 1.875em;
        max-width: 292px;
        margin-bottom: 20px; } }
    .subpage__title--news {
      width: 100%; }
    .subpage__title--jobOffers {
      font-size: 1.2em;
      color: #000;
      border: 0;
      margin-bottom: 0;
      width: 100%; }
    .subpage__title--gatherings {
      width: 100%;
      text-transform: uppercase; }
  .subpage__date {
    font-size: 0.9em;
    font-style: italic;
    text-align: right;
    color: #000 !important;
    padding-bottom: 20px;
    border-bottom: 2px solid #000;
    margin-bottom: 40px;
    margin-top: 20px; }
  .subpage__content {
    color: #000 !important;
    font-size: 0.938em;
    line-height: 120%; }
    .subpage__content a {
      color: #000 !important; }
    @media (max-width: 767px) {
      .subpage__content {
        font-size: 0.813em; } }
  .subpage__back {
    font-size: 1em;
    color: #000 !important;
    text-align: right;
    display: block;
    margin: 20px 0; }

.page-gallery {
  margin-top: 40px; }
  .page-gallery__link {
    display: inline-block;
    margin: 10px; }
    @media (max-width: 767px) {
      .page-gallery__link {
        max-width: 140px; } }

.page-file__title {
  font-size: 1.2em;
  font-weight: bold;
  color: #000 !important;
  margin-bottom: 10px;
  margin-top: 30px; }

.page-file .file {
  margin-bottom: 10px; }
  .page-file .file a {
    color: #000 !important;
    font-size: 1em; }
    .page-file .file a:hover, .page-file .file a:focus {
      color: #000 !important;
      text-decoration: none; }
  .page-file .file__img {
    width: 30px; }

.page-result-text {
  font-size: 1.25em;
  margin-bottom: 15px; }

.page-result-link {
  font-size: 1.25em;
  display: block;
  text-decoration: none;
  color: #000 !important;
  line-height: 120%;
  margin-bottom: 20px; }
  .page-result-link:hover {
    color: #000; }

.news {
  background: url("/media/default/news-bg.jpg") no-repeat;
  background-size: cover;
  padding: 36px 35px;
  padding-bottom: 52px;
  height: 100%; }
  @media (max-width: 1199px) {
    .news {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px; } }
  @media (max-width: 767px) {
    .news {
      padding-top: 20px; } }
  .news__header {
    margin: 0;
    padding: 0;
    font-size: 2.813em;
    color: #fff;
    margin-bottom: 40px; }
    @media (max-width: 1199px) {
      .news__header {
        margin-bottom: 20px;
        position: relative;
        left: -70px; } }
    @media (max-width: 767px) {
      .news__header {
        font-size: 1.875em;
        position: static;
        text-align: center; } }
  .news .news-container {
    max-width: 870px; }
    @media (max-width: 1199px) {
      .news .news-container {
        max-width: 568px;
        margin: 0 auto; } }
    @media (max-width: 767px) {
      .news .news-container {
        max-width: 291px; } }
  .news .news-block {
    max-width: 568px;
    background: #fff;
    padding: 12px;
    padding-bottom: 50px;
    display: block;
    min-height: 568px; }
    @media (max-width: 1399px) {
      .news .news-block {
        min-height: 495px; } }
    @media (max-width: 1199px) {
      .news .news-block {
        min-height: 568px;
        margin: 0 auto;
        margin-bottom: 40px; } }
    @media (max-width: 767px) {
      .news .news-block {
        padding: 7px;
        min-height: 424px;
        margin-bottom: 20px; } }
    .news .news-block:hover, .news .news-block:focus {
      text-decoration: none; }
    .news .news-block--next {
      margin-left: 20px;
      padding-bottom: 32px;
      min-height: 267px; }
      @media (max-width: 1399px) {
        .news .news-block--next {
          min-height: 230px; } }
      @media (max-width: 1199px) {
        .news .news-block--next {
          max-width: 267px;
          min-height: 267px; } }
      @media (max-width: 767px) {
        .news .news-block--next {
          margin: 0 auto;
          padding: 10px; } }
    .news .news-block--top {
      margin-bottom: 32px;
      min-height: 267px; }
      @media (max-width: 1399px) {
        .news .news-block--top {
          min-height: 230px; } }
      @media (max-width: 1199px) {
        .news .news-block--top {
          max-width: 267px;
          min-height: 267px;
          margin-left: 0; } }
      @media (max-width: 767px) {
        .news .news-block--top {
          margin: 0 auto;
          margin-bottom: 20px; } }
    .news .news-block__title {
      font-size: 1.250em;
      color: #000;
      font-weight: bold;
      margin: 0;
      padding: 0;
      margin-top: 12px; }
      @media (max-width: 767px) {
        .news .news-block__title {
          font-size: 1.125em; } }
      .news .news-block__title--next {
        font-size: 1em;
        margin-top: 15px; }
    .news .news-block__date {
      color: #000;
      font-size: 0.813em;
      margin-top: 5px; }
      .news .news-block__date--next {
        font-size: 0.750em; }
    .news .news-block__description {
      color: #000;
      font-size: 0.875em;
      margin-top: 9px;
      max-width: 540px; }
      @media (max-width: 767px) {
        .news .news-block__description {
          font-size: 0.813em; } }
    .news .news-block__img {
      width: 100%;
      max-width: auto;
      max-height: 363px; }
      .news .news-block__img--next {
        max-height: 159px; }

.news-list {
  margin-bottom: 30px;
  padding-bottom: 30px;
  display: block;
  color: #000 !important;
  border-bottom: 1px solid #000; }
  @media (max-width: 767px) {
    .news-list {
      max-width: 260px;
      margin: 0 auto;
      margin-bottom: 30px; } }
  .news-list:hover, .news-list:focus {
    text-decoration: none;
    color: #000 !important; }
    .news-list:hover .news-list__button, .news-list:focus .news-list__button {
      text-decoration: none;
      background: #fff;
      color: #000;
      border-color: #000; }
  @media (max-width: 767px) {
    .news-list__col {
      padding: 0; } }
  .news-list__img {
    max-width: auto;
    width: 100%;
    max-height: 170px; }
    @media (max-width: 767px) {
      .news-list__img {
        margin-bottom: 10px; } }
  .news-list__title {
    font-weight: bold;
    margin: 0;
    font-size: 1.25em;
    margin-bottom: 5px; }
  .news-list__date {
    font-size: 0.85em;
    margin-bottom: 10px; }
  .news-list__desc {
    font-size: 1em; }
  .news-list__button {
    display: block;
    text-align: center;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    font-size: 0.9em;
    width: 170px;
    padding: 10px 0;
    float: right;
    margin-top: 10px;
    transition: background 0.3s, color 0.3s;
    -webkit-transition: background 0.3s, color 0.3s;
    -moz-transition: background 0.3s, color 0.3s;
    -ms-transition: background 0.3s, color 0.3s;
    -o-transition: background 0.3s, color 0.3s; }

.sprite {
  background: url("/media/default/navigation.png") no-repeat top left; }

.sprite.normalf {
  background-position: 0px 0px;
  width: 34px;
  height: 34px; }

.sprite.normalf-active {
  background-position: 0px -44px;
  width: 34px;
  height: 34px; }

.sprite.mediumf {
  background-position: 0px -88px;
  width: 34px;
  height: 34px; }

.sprite.mediumf-active {
  background-position: 0px -132px;
  width: 34px;
  height: 34px; }

.sprite.bigf {
  background-position: 0px -176px;
  width: 34px;
  height: 34px; }

.sprite.bigf-active {
  background-position: 0px -220px;
  width: 34px;
  height: 34px; }

.sprite.contrast {
  background-position: 0px -264px;
  width: 24px;
  height: 24px; }

.sprite.mapa-strony {
  background-position: 0px -298px;
  width: 27px;
  height: 15px; }

.sprite.home {
  background-position: 0px -323px;
  width: 23px;
  height: 18px; }

.sprite.mail {
  background-position: 0px -351px;
  width: 20px;
  height: 16px; }

.sprite.phone {
  background-position: 0px -377px;
  width: 18px;
  height: 18px; }

.sprite.search {
  background-position: 0px -405px;
  width: 33px;
  height: 29px; }

.hiddenLink {
  font-size: 0px; }

.top {
  padding-top: 23px;
  padding-bottom: 30px;
  background: #fff; }
  @media (max-width: 767px) {
    .top {
      display: none; } }
  .top__container {
    padding: 0; }
  .top__img {
    position: relative;
    left: 20px;
    margin: 0 auto; }
    @media (max-width: 1199px) {
      .top__img {
        position: static; } }

.fund {
  width: 80%;
  margin: 0 auto; }
  @media (max-width: 1399px) {
    .fund {
      width: 100%; } }
  .fund__text {
    max-width: 565px;
    color: #000;
    text-align: center;
    font-family: "Tahoma";
    line-height: 120%;
    float: left;
    padding: 0 5px;
    font-size: 0.8em !important; }
    @media (max-width: 1199px) {
      .fund__text {
        max-width: 465px; } }
    .fund__text--bold {
      font-weight: bold; }
  .fund__img {
    max-width: 19%;
    height: auto;
    float: left; }

.beam {
  background: #000;
  min-height: 51px; }
  @media (max-width: 767px) {
    .beam {
      min-height: 100px; } }
  .beam__container {
    padding: 0; }
  .beam__center-col {
    position: relative; }
  .beam__right-col {
    position: relative; }

.wcag {
  position: relative;
  top: 16px; }
  @media (max-width: 767px) {
    .wcag {
      max-width: 300px;
      max-width: 220px;
      margin: 0 auto;
      margin-bottom: 15px; } }
  .wcag__contrast {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -264px;
    width: 24px;
    height: 24px;
    width: 24px;
    height: 24px;
    float: left;
    margin-left: 4px; }
  .wcag__map {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -298px;
    width: 27px;
    height: 15px;
    width: 27px;
    height: 15px;
    float: left;
    margin-left: 15px;
    position: relative;
    top: 5px; }
  .wcag__normalf {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px 0px;
    width: 34px;
    height: 34px;
    width: 34px;
    height: 34px;
    float: left;
    margin-left: 31px;
    position: relative;
    top: -5px; }
    .wcag__normalf--active {
      background-position: 0px -44px; }
  .wcag__mediumf {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -88px;
    width: 34px;
    height: 34px;
    width: 34px;
    height: 34px;
    float: left;
    margin-left: 2px;
    position: relative;
    top: -5px;
    margin-left: 6px; }
    .wcag__mediumf--active {
      background-position: 0px -132px; }
  .wcag__bigf {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -176px;
    width: 34px;
    height: 34px;
    width: 34px;
    height: 34px;
    float: left;
    margin-left: 2px;
    position: relative;
    top: -5px;
    margin-left: 6px; }
    .wcag__bigf--active {
      background-position: 0px -220px; }

.navigation {
  width: 100px;
  position: absolute;
  right: 185px;
  top: 18px; }
  @media (max-width: 1199px) {
    .navigation {
      right: 130px; } }
  @media (max-width: 767px) {
    .navigation {
      position: static;
      margin-top: 18px;
      margin-left: 20px; } }
  .navigation__home {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -323px;
    width: 23px;
    height: 18px;
    width: 23px;
    height: 18px;
    float: left; }
  .navigation__mail {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -351px;
    width: 20px;
    height: 16px;
    width: 20px;
    height: 16px;
    float: left;
    position: relative;
    margin-left: 20px;
    top: 2px; }
  .navigation__phone {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -377px;
    width: 18px;
    height: 18px;
    width: 18px;
    height: 18px;
    float: left;
    position: relative;
    margin-left: 18px;
    top: 1px; }

.language {
  position: absolute;
  right: 86px;
  top: 19px; }
  .language__link {
    text-transform: uppercase;
    float: left;
    font-size: 0.750em;
    color: #fff;
    padding: 0 10px;
    border-right: 1px solid #fff; }
    .language__link:last-child {
      border: 0; }
    .language__link:hover, .language__link:focus {
      text-decoration: none;
      color: #fff; }

.search {
  position: absolute;
  right: 27px;
  top: 12px;
  padding-left: 17px;
  border-left: 1px solid #fff; }
  .search__button {
    background: url("/media/default/navigation.png") no-repeat top left;
    background-position: 0px -377px;
    width: 18px;
    height: 18px;
    width: 18px;
    height: 18px;
    background-position: 0px -405px;
    width: 33px;
    height: 29px;
    display: block; }

.search-block {
  background: #000;
  padding: 100px;
  display: block;
  padding: 50px;
  position: relative;
  z-index: 100;
  display: none; }
  @media (max-width: 1199px) {
    .search-block {
      padding: 30px; } }
  @media (max-width: 767px) {
    .search-block {
      padding: 30px 0; } }
  .search-block__form {
    max-width: 800px;
    margin: 0 auto;
    position: relative; }
    @media (max-width: 1199px) {
      .search-block__form {
        max-width: 80%; } }
  .search-block__input {
    width: 100%;
    height: 53px;
    line-height: 53px;
    background: #fff;
    font-size: 1em;
    color: #000;
    margin-bottom: 30px;
    padding-left: 10px;
    line-height: 53px; }
    @media (max-width: 767px) {
      .search-block__input {
        position: relative; } }
    .search-block__input::placeholder {
      color: #000;
      font-style: italic;
      font-size: 1em; }
    .search-block__input:focus {
      outline: none; }
    @media (max-width: 1199px) {
      .search-block__input {
        height: 47px;
        border: 0;
        padding-right: 50px;
        line-height: 47px; } }
  .search-block__button {
    display: block;
    width: 210px;
    background: transparent;
    border: 2px solid #FAFAFA;
    margin: 0 auto;
    font-size: 1em;
    color: #FAFAFA;
    margin-top: 10px;
    padding: 18px 0;
    font-weight: 600;
    text-transform: uppercase;
    outline: 0;
    transition: background 0.3s;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -o-transition: background 0.3s; }
    @media (max-width: 767px) {
      .search-block__button {
        font-size: 0.85em;
        padding: 12px 0;
        width: 180px;
        margin-top: 0; } }
    .search-block__button:hover, .search-block__button:focus {
      background: #FAFAFA;
      color: #000;
      font-weight: 600; }
  .search-block__close {
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    position: absolute;
    right: -100px;
    top: -10px;
    font-size: 0; }
    @media (max-width: 1199px) {
      .search-block__close {
        right: -60px; } }
    @media (max-width: 767px) {
      .search-block__close {
        width: 25px;
        height: 25px;
        right: -30px; } }
  .search-block__span {
    display: block;
    width: 50px;
    height: 5px;
    margin-bottom: 4px;
    background: #FAFAFA; }
    @media (max-width: 767px) {
      .search-block__span {
        width: 25px;
        height: 3px; } }
    .search-block__span--ft {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      position: relative;
      top: 24px; }
      @media (max-width: 767px) {
        .search-block__span--ft {
          top: 21px; } }
    .search-block__span--sd {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      top: 14px;
      position: relative; }

.header {
  background: #FAFAFA;
  padding: 23px 0;
  padding-bottom: 11px; }
  @media (max-width: 1199px) {
    .header {
      padding-bottom: 0; } }
  @media (max-width: 767px) {
    .header {
      padding: 15px 8px; } }
  .header__container {
    padding: 0;
    position: relative; }
  .header__img {
    float: left;
    position: relative;
    z-index: 20; }
    @media (max-width: 1199px) {
      .header__img {
        max-width: 150px; } }
    @media (max-width: 767px) {
      .header__img {
        max-width: 120px; } }
  .header .header-contact {
    float: right;
    margin-top: -5px;
    margin-right: 40px;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .header .header-contact {
        margin-bottom: 0;
        max-width: 120px;
        margin-right: 25px; } }
    .header .header-contact__text {
      color: #000;
      font-size: 0.938em;
      line-height: 120%; }
      @media (max-width: 767px) {
        .header .header-contact__text {
          font-size: 12px; } }
      .header .header-contact__text--hours {
        font-size: 0.750em;
        margin-top: 15px; }
        @media (max-width: 767px) {
          .header .header-contact__text--hours {
            margin-top: 5px;
            font-size: 12px; } }
    .header .header-contact__link {
      color: #000; }
      .header .header-contact__link:hover, .header .header-contact__link:focus {
        text-decoration: none;
        color: #000; }

.menu {
  position: relative;
  z-index: 10;
  background: #FAFAFA; }
  @media (max-width: 1199px) {
    .menu {
      display: none; } }
  .menu__container {
    padding: 0; }
  .menu .menu-list {
    float: right; }
    .menu .menu-list__name {
      text-transform: uppercase;
      color: #fff;
      width: 378px;
      text-align: center;
      padding: 11px 0;
      padding-bottom: 15px;
      display: inline-block;
      font-size: 1.063em; }
      .menu .menu-list__name:hover, .menu .menu-list__name:focus {
        text-decoration: none; }
      .menu .menu-list__name--first {
        background: #000; }
      .menu .menu-list__name--second {
        background: #000 !important;
        margin-left: 7px; }
  .menu .menu-tabs--first {
    background: #000; }
  .menu .menu-tabs--second {
    background: #000 !important; }
  .menu .menu-tabs.menu-tabs-active {
    display: block; }
  .menu .menu-tabs__container {
    max-width: 73.125em;
    margin: 0 auto; }
  .menu .menu-tabs__ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    @media (max-width: 1399px) {
      .menu .menu-tabs__ul {
        padding: 0 10px; } }
  .menu .menu-tabs__li {
    float: left;
    margin: 23px 0; }
    .menu .menu-tabs__li:first-child .menu-tabs__a {
      padding-left: 0; }
    .menu .menu-tabs__li:last-child .menu-tabs__a {
      border: 0;
      padding-right: 0; }
    .menu .menu-tabs__li.active .menu-tabs__a {
      color: #000; }
    .menu .menu-tabs__li.active a {
      color: #000; }
    .menu .menu-tabs__li a {
      display: block;
      color: #fff;
      font-size: 0.813em;
      text-transform: uppercase;
      padding: 0 20px;
      padding-right: 23px;
      border-right: 1px solid #fff;
      transition: color 0.2s;
      -webkit-transition: color 0.2s;
      -moz-transition: color 0.2s;
      -ms-transition: color 0.2s;
      -o-transition: color 0.2s; }
      @media (max-width: 1399px) {
        .menu .menu-tabs__li a {
          font-size: 0.750em; } }
      .menu .menu-tabs__li a:hover, .menu .menu-tabs__li a:focus {
        text-decoration: none;
        color: #000; }
  .menu .menu-tabs__a {
    display: block;
    color: #fff;
    font-size: 0.813em;
    text-transform: uppercase;
    padding: 0 20px;
    padding-right: 23px;
    border-right: 1px solid #fff;
    transition: color 0.2s;
    -webkit-transition: color 0.2s;
    -moz-transition: color 0.2s;
    -ms-transition: color 0.2s;
    -o-transition: color 0.2s; }
    @media (max-width: 1399px) {
      .menu .menu-tabs__a {
        font-size: 0.750em; } }
    .menu .menu-tabs__a:hover, .menu .menu-tabs__a:focus {
      text-decoration: none;
      color: #000; }

.dropdown-menu {
  position: static;
  width: 100%;
  box-shadow: none;
  border: 0;
  font-size: 1em;
  margin-bottom: 20px;
  border-bottom: 1px solid #000;
  padding-left: 15px; }
  @media (max-width: 1199px) {
    .dropdown-menu {
      background: #000 !important !important;
      border: 0;
      color: #fff !important;
      padding: 0;
      border-bottom: 2px solid rgba(255, 255, 255, 0.25); } }
  .dropdown-menu .menu-tabs__a {
    text-transform: uppercase; }
    .dropdown-menu .menu-tabs__a:hover, .dropdown-menu .menu-tabs__a:focus {
      background: none; }
    @media (max-width: 1199px) {
      .dropdown-menu .menu-tabs__a {
        border: 0 !important;
        color: #fff; } }
  @media (max-width: 1199px) {
    .dropdown-menu {
      float: none;
      border-top: 0.5px solid rgba(255, 255, 255, 0.25);
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
      margin: 0; } }

.mobile-menu {
  display: none; }
  @media (max-width: 1199px) {
    .mobile-menu {
      display: block; } }
  .mobile-menu__ul {
    padding: 0;
    margin: 0;
    list-style-type: 0; }
    .mobile-menu__ul--first {
      background: #000; }
    .mobile-menu__ul--second {
      background: #000 !important; }
    .mobile-menu__ul--competitions {
      background: #000 !important; }
  .mobile-menu__li.activeMenu .dropdown {
    display: block; }
  .mobile-menu__a {
    display: block;
    font-size: 1.063em;
    color: #fff;
    font-weight: 400;
    text-align: center;
    padding: 15px 10px;
    text-transform: uppercase; }
    .mobile-menu__a:hover, .mobile-menu__a:focus {
      text-decoration: none;
      color: #fff; }
  .mobile-menu .menu-tabs__li:first-child .menu-tabs__a {
    border-top: 1px solid #fff; }
  .mobile-menu .menu-tabs__li a {
    display: block;
    text-align: center;
    font-size: 0.813em;
    text-transform: uppercase;
    padding: 15px 10px;
    border-top: 0.5px solid rgba(255, 255, 255, 0.25);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25); }
    .mobile-menu .menu-tabs__li a:hover, .mobile-menu .menu-tabs__li a:focus {
      text-decoration: none;
      color: #fff; }
  .mobile-menu .menu-tabs__a {
    display: block;
    text-align: center;
    font-size: 0.813em;
    text-transform: uppercase;
    padding: 15px 0;
    border-top: 0.5px solid rgba(255, 255, 255, 0.25);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.25); }
    .mobile-menu .menu-tabs__a:hover, .mobile-menu .menu-tabs__a:focus {
      text-decoration: none;
      color: #fff; }
  .mobile-menu .menu-tabs__li.active .menu-tabs__a,
  .mobile-menu .menu-tabs__li.active a {
    color: #000; }
  .mobile-menu .competitions-list {
    padding: 0;
    margin: 0;
    list-style-type: 0; }
    .mobile-menu .competitions-list__li:first-child .competitions-list__a {
      border-top: 1px solid #fff; }
    .mobile-menu .competitions-list__a {
      display: block;
      text-align: center;
      font-size: 0.813em;
      text-transform: uppercase;
      padding: 15px 0;
      border-top: 0.5px solid rgba(255, 255, 255, 0.25);
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.25); }
      .mobile-menu .competitions-list__a:hover, .mobile-menu .competitions-list__a:focus {
        text-decoration: none;
        color: #fff; }
  .mobile-menu .dropdown {
    display: none; }

.dropdown.menu-tabs__li.active.open .dropdown-menu .menu-tabs__a {
  color: #000; }
  @media (max-width: 1199px) {
    .dropdown.menu-tabs__li.active.open .dropdown-menu .menu-tabs__a {
      color: #fff; } }

.dropdown.menu-tabs__li.active.open .dropdown-menu .menu-tabs__li.active .menu-tabs__a {
  color: #000; }
  @media (max-width: 1199px) {
    .dropdown.menu-tabs__li.active.open .dropdown-menu .menu-tabs__li.active .menu-tabs__a {
      color: #000;
      background: none; } }

.competitions {
  padding-top: 10px;
  width: 250px;
  background: #fff;
  position: relative;
  float: right; }
  @media (max-width: 1199px) {
    .competitions {
      float: none; } }
  .competitions::before {
    display: block;
    content: "";
    height: 100%;
    width: 100vw;
    background: #fff;
    position: absolute;
    left: -100vw;
    top: 0; }
  .competitions__title {
    font-size: 2.063em;
    max-width: 250px;
    color: #fff;
    font-weight: 700;
    background: #000 !important;
    text-transform: uppercase;
    padding: 29px 8px;
    letter-spacing: 1px; }
    .competitions__title--offers {
      color: #000;
      padding: 0;
      max-width: 100%;
      background: none;
      line-height: 120%;
      font-size: 1.2em;
      background: none !important;
      margin-top: 30px; }
  .competitions .competitions-list {
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .competitions .competitions-list--offers {
      margin-top: 20px; }
    .competitions .competitions-list__li {
      border-bottom: 1px solid #000; }
      .competitions .competitions-list__li:last-child {
        border: 0; }
      .competitions .competitions-list__li--offers:first-child {
        border-top: 1px solid #000 !important; }
      .competitions .competitions-list__li--offers:last-child {
        border-bottom: 1px solid #000 !important; }
      .competitions .competitions-list__li.active .competitions-list__a {
        color: #000 !important; }
    .competitions .competitions-list__a {
      color: #000;
      font-size: 0.813em;
      padding: 16px 0;
      display: block;
      max-width: 230px;
      line-height: 120%;
      font-weight: 600; }
      .competitions .competitions-list__a--big {
        font-size: 1.2em !important;
        text-transform: uppercase;
        margin-bottom: 5px; }
      .competitions .competitions-list__a:hover, .competitions .competitions-list__a:focus {
        color: #000 !important;
        text-decoration: none; }
    .competitions .competitions-list .menu-tabs__li {
      border-bottom: 1px solid #000; }
      .competitions .competitions-list .menu-tabs__li:last-child {
        border: 0; }
      .competitions .competitions-list .menu-tabs__li--offers:first-child {
        border-top: 1px solid #000; }
      .competitions .competitions-list .menu-tabs__li--offers:last-child {
        border-bottom: 1px solid #000; }
      .competitions .competitions-list .menu-tabs__li.active .menu-tabs__a {
        color: #000 !important; }
      .competitions .competitions-list .menu-tabs__li.active .dropdown-menu .menu-tabs__li.active .menu-tabs__a {
        color: #000 !important; }
      .competitions .competitions-list .menu-tabs__li.active .dropdown-menu .menu-tabs__a {
        color: #000; }
    .competitions .competitions-list .menu-tabs__a {
      color: #000;
      font-size: 0.813em;
      padding: 16px 0;
      display: block;
      max-width: 230px;
      line-height: 120%;
      font-weight: 600; }
      .competitions .competitions-list .menu-tabs__a:hover, .competitions .competitions-list .menu-tabs__a:focus {
        color: #000 !important;
        text-decoration: none; }

.projects {
  background: #fff;
  padding: 45px 0;
  padding-bottom: 55px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2); }
  @media (max-width: 1199px) {
    .projects {
      padding-bottom: 50px; } }
  @media (max-width: 767px) {
    .projects {
      padding-top: 25px; } }
  .projects__container {
    padding: 0; }
    @media (max-width: 1199px) {
      .projects__container {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 768px;
        width: auto; } }
    @media (max-width: 767px) {
      .projects__container {
        padding-right: 10px;
        padding-left: 10px; } }
  .projects__header {
    max-width: 523px;
    border-bottom: 2px solid #000; }
    .projects__header.otherColor {
      border-color: #000 !important; }
    @media (max-width: 767px) {
      .projects__header {
        max-width: 292px; } }
  .projects__title {
    margin: 0;
    padding: 0;
    font-size: 2.813em;
    color: #000 !important;
    line-height: 120%;
    letter-spacing: 1px;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .projects__title {
        font-size: 1.875em; } }
  .projects__years {
    color: #000 !important;
    font-size: 2.125em;
    font-weight: 300;
    line-height: 120%;
    margin-bottom: 10px;
    letter-spacing: 1px; }
    @media (max-width: 767px) {
      .projects__years {
        display: none; } }
  .projects__text {
    font-size: 0.938em;
    color: #000 !important;
    line-height: 120%;
    margin-top: 50px; }
    .projects__text--bold {
      font-weight: bold;
      display: block; }
    @media (max-width: 767px) {
      .projects__text {
        font-size: 0.813em;
        margin-top: 15px; } }
  .projects__empty {
    margin: 10px 0; }
  .projects .projects-content__header {
    color: #000 !important;
    font-size: 1.625em;
    line-height: 120%;
    letter-spacing: 0.5px; }
    @media (max-width: 767px) {
      .projects .projects-content__header {
        font-size: 1.250em;
        max-width: 232px; } }
  .projects .projects-content__city {
    color: #000 !important;
    font-size: 1.625em;
    font-weight: bold;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 10px; }
  .projects .project-tabs {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-top: 26px; }
    @media (max-width: 767px) {
      .projects .project-tabs {
        margin: 0; } }
    .projects .project-tabs--dropdown {
      display: none;
      margin: 0; }
      .projects .project-tabs--dropdown .project-tabs__li {
        border-bottom: 1px dashed #000; }
      .projects .project-tabs--dropdown .project-tabs__a {
        font-size: 1em; }
    .projects .project-tabs__li {
      padding: 20px 0;
      border-bottom: 1px solid #000; }
      .projects .project-tabs__li.active {
        border-bottom: 0; }
        .projects .project-tabs__li.active .project-tabs--dropdown {
          display: block; }
          .projects .project-tabs__li.active .project-tabs--dropdown .project-tabs__a {
            font-style: italic;
            font-weight: 400; }
        .projects .project-tabs__li.active .project-tabs__a {
          font-weight: bold; }
    .projects .project-tabs__a {
      font-size: 1.125em;
      color: #000 !important;
      letter-spacing: 0.5px; }
      .projects .project-tabs__a:hover, .projects .project-tabs__a:focus {
        text-decoration: none;
        font-weight: bold; }
  .projects .about-city {
    margin-top: 40px; }
    @media (max-width: 1199px) {
      .projects .about-city {
        max-width: 568px;
        margin-left: auto;
        margin-right: auto; } }
    .projects .about-city__text {
      font-size: 0.750em;
      color: #000 !important;
      line-height: 120%;
      float: left;
      width: 70%; }
      @media (max-width: 767px) {
        .projects .about-city__text {
          float: none;
          width: 100%; } }
    .projects .about-city__arms {
      float: right;
      margin-top: -7px; }
      @media (max-width: 767px) {
        .projects .about-city__arms {
          float: none;
          width: 100%;
          margin-top: 15px; } }
    @media (max-width: 767px) {
      .projects .about-city__arms-img {
        max-height: 103px;
        margin: 0 auto; } }
    .projects .about-city__photo {
      margin-top: 20px; }

.projects-map {
  margin-top: -11px;
  margin-left: 12px;
  max-width: 358px; }
  .projects-map__title {
    color: #000 !important;
    font-size: 0.938em;
    line-height: 120%;
    letter-spacing: 0.3px; }
    .projects-map__title--about {
      font-size: 1.25em;
      margin-bottom: 5px; }
  .projects-map__area {
    outline: none; }
  .projects-map .map-block {
    margin-top: 30px;
    position: relative; }
    .projects-map .map-block--about {
      margin-top: 10px; }
    .projects-map .map-block__city {
      font-size: 0.938em;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      min-width: 144px;
      padding: 12px 6px;
      background: #000;
      border: 1px solid #fff;
      position: absolute;
      right: 80px;
      top: 110px;
      transition: top 0.3s, right 0.3s; }
      .projects-map .map-block__city--about {
        font-size: 1.1em; }
      .projects-map .map-block__city.otherColor {
        background: #000 !important; }
        .projects-map .map-block__city.otherColor::after {
          border-top: 10px solid #000 !important;
          border-left: 5px solid #000 !important; }
      .projects-map .map-block__city::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -20px;
        left: 32px;
        border-top: 10px solid #000;
        border-right: 5px solid transparent;
        border-left: 5px solid #000;
        border-bottom: 10px solid transparent; }
      .projects-map .map-block__city::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -23px;
        left: 31px;
        border-top: 11px solid #fff;
        border-right: 6px solid transparent;
        border-left: 6px solid #fff;
        border-bottom: 11px solid transparent; }

.chooseCommune {
  margin-top: 35px; }
  .chooseCommune--about {
    margin-top: -10px; }
  .chooseCommune__header {
    color: #000;
    line-height: 120%;
    font-size: 1.063em;
    margin-top: 10px;
    letter-spacing: 0.1px; }
    .chooseCommune__header.otherColor {
      color: #000 !important; }
    @media (max-width: 1199px) {
      .chooseCommune__header--desktop {
        display: none; } }
    @media (max-width: 767px) {
      .chooseCommune__header {
        font-size: 1.063em; } }
    .chooseCommune__header--mobile {
      display: none; }
      @media (max-width: 1199px) {
        .chooseCommune__header--mobile {
          display: block; } }
    .chooseCommune__header--big {
      font-size: 2.063em;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      margin: 0; }
      @media (max-width: 767px) {
        .chooseCommune__header--big {
          font-size: 1.688em; } }
    .chooseCommune__header--about {
      font-size: 1.163em; }
    .chooseCommune__header--about-big {
      font-size: 2.2em; }
  .chooseCommune .chooseCommune-list {
    width: 397px; }
    .chooseCommune .chooseCommune-list__select {
      width: 397px;
      background-color: #fff;
      border: 1px solid #000;
      height: 41px;
      font-size: 0.938em;
      color: #000;
      padding: 0 6px;
      padding-left: 10px;
      margin-top: 20px;
      position: relative;
      background-image: url("/media/default/select-arrow.jpg");
      background-position: calc(100% - 8px) calc(1em - 6px), calc(100% - 15px) calc(1em + 2px), 100% 0;
      background-repeat: no-repeat;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none; }
      @media (max-width: 767px) {
        .chooseCommune .chooseCommune-list__select {
          width: 293px; } }
      .chooseCommune .chooseCommune-list__select--about {
        font-size: 1em; }
    .chooseCommune .chooseCommune-list__option {
      font-size: 0.938em; }
      .chooseCommune .chooseCommune-list__option--about {
        font-size: 1em; }
  .chooseCommune__choice {
    font-size: 15px;
    color: #000 !important;
    margin-top: 33px; }
    @media (max-width: 767px) {
      .chooseCommune__choice {
        margin-top: 25px; } }
    .chooseCommune__choice--commune {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.125em;
      margin-left: 15px;
      letter-spacing: 1.1px; }

.projectsList {
  margin-bottom: 20px; }
  .projectsList__link {
    display: block;
    border-bottom: 1px solid #000;
    margin-bottom: 20px; }
    .projectsList__link:hover, .projectsList__link:focus {
      text-decoration: none; }
    .projectsList__link:hover {
      color: #000; }
  .projectsList__title {
    font-size: 1.1em;
    font-weight: bold;
    line-height: 130%; }
  .projectsList__content {
    display: none; }

.gallery {
  background: url("/media/default/galleryBg.jpg") no-repeat;
  background-size: cover;
  padding: 30px 0;
  padding-bottom: 40px; }
  @media (max-width: 767px) {
    .gallery {
      padding-bottom: 35px; } }
  .gallery__pause {
    margin-top: 20px;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    display: inline-block; }
    .gallery__pause:hover {
      color: inherit; }
      .gallery__pause:hover:focus {
        outline: 2px solid #0000ff;
        color: inherit; }
  .gallery__container {
    padding: 0; }
    @media (max-width: 1199px) {
      .gallery__container {
        padding: 0 30px; } }
    @media (max-width: 767px) {
      .gallery__container {
        padding: 0 15px;
        max-width: 320px; } }
  .gallery__header {
    font-size: 2.813em;
    color: #000 !important;
    text-transform: uppercase;
    line-height: 120%;
    margin: 0;
    letter-spacing: 0.7px;
    width: 525px;
    padding-bottom: 8px;
    border-bottom: 2px solid #000; }
    .gallery__header.otherColor {
      border-color: #000 !important; }
    @media (max-width: 767px) {
      .gallery__header {
        width: 292px;
        font-size: 1.875em; } }
  .gallery .gallery-carousel {
    max-width: 1080px;
    margin: 0 auto;
    margin-top: 46px;
    position: relative; }
    @media (max-width: 1199px) {
      .gallery .gallery-carousel {
        max-width: 540px; } }
    @media (max-width: 767px) {
      .gallery .gallery-carousel {
        max-width: 233px;
        margin-top: 25px; } }
  .gallery .gallery-box {
    position: relative;
    display: block;
    background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.75)); }
    .gallery .gallery-box__img {
      position: relative;
      z-index: -1; }
  .gallery .gallery-desc {
    position: absolute;
    width: 100%;
    bottom: 10px;
    right: 0;
    left: 0;
    margin: auto;
    width: 230px; }
    @media (max-width: 767px) {
      .gallery .gallery-desc {
        width: 196px; } }
    .gallery .gallery-desc__title {
      font-size: 1.250em;
      color: #fff;
      font-weight: bold;
      line-height: 120%;
      margin: 0; }
      @media (max-width: 767px) {
        .gallery .gallery-desc__title {
          font-size: 1em; } }
    .gallery .gallery-desc__date {
      font-size: 0.813em;
      color: #fff;
      line-height: 120%;
      margin-top: 5px; }
      @media (max-width: 767px) {
        .gallery .gallery-desc__date {
          font-size: 0.78em; } }
  .gallery .gallery-block {
    display: none; }
    .gallery .gallery-block.active {
      display: block; }

.owl-nav {
  width: 100%;
  margin-top: -150px;
  margin-bottom: 190px; }

.owl-prev {
  width: 40px;
  height: 40px;
  background: url("/media/default/gallery-arrow-prev.png") no-repeat;
  display: block;
  position: absolute;
  left: -45px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s; }
  @media (max-width: 767px) {
    .owl-prev {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -o-transform: scale(0.9);
      left: -42px; } }
  .owl-prev:hover {
    opacity: 0.75; }

.owl-next {
  width: 40px;
  height: 40px;
  background: url("/media/default/gallery-arrow-next.png") no-repeat;
  display: block;
  position: absolute;
  right: -45px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s; }
  @media (max-width: 767px) {
    .owl-next {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -o-transform: scale(0.9);
      right: -42px; } }
  .owl-next:hover {
    opacity: 0.75; }

.gallery-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .gallery-list .menu-tabs__li {
    padding: 20px 0;
    border-bottom: 1px dashed #000; }
  .gallery-list .menu-tabs__a {
    font-size: 1.25em;
    display: block;
    white-space: normal; }
    .gallery-list .menu-tabs__a:hover, .gallery-list .menu-tabs__a:focus {
      color: #000 !important;
      text-decoration: none; }
  .gallery-list .dropdown-menu {
    position: static;
    margin: 0;
    padding: 0;
    float: none;
    background: none !important;
    box-shadow: none;
    border: 0;
    font-size: 1.025em; }
    .gallery-list .dropdown-menu .menu-tabs__li {
      border: 0;
      padding-bottom: 0; }
    .gallery-list .dropdown-menu .menu-tabs__a {
      color: #000 !important; }
      .gallery-list .dropdown-menu .menu-tabs__a:hover, .gallery-list .dropdown-menu .menu-tabs__a:focus {
        background: none;
        color: #000; }

.dropdown-backdrop {
  display: none !important; }

.logos {
  background: #fff;
  padding: 45px;
  padding-bottom: 0; }
  @media (max-width: 767px) {
    .logos {
      padding: 10px; } }
  .logos__pause {
    margin: 20px;
    text-transform: uppercase;
    color: #000;
    font-weight: bold;
    display: inline-block; }
    .logos__pause:hover {
      color: inherit; }
      .logos__pause:hover:focus {
        outline: 2px solid #0000ff;
        color: inherit; }
  .logos__container {
    padding: 0; }
    @media (max-width: 1199px) {
      .logos__container {
        padding: 0 30px;
        width: auto;
        max-width: 768px; } }
    @media (max-width: 767px) {
      .logos__container {
        padding: 0;
        max-width: 320px; } }
  .logos .logos-carousel {
    max-width: 1150px;
    margin: 0 auto;
    padding-bottom: 75px;
    border-bottom: 1px solid #000; }
    @media (max-width: 1199px) {
      .logos .logos-carousel {
        padding-bottom: 40px; } }
    @media (max-width: 767px) {
      .logos .logos-carousel {
        padding-bottom: 20px;
        padding-top: 10px; } }
  .logos .logos-bottom {
    margin-top: 25px;
    padding-bottom: 45px;
    border-bottom: 1px solid #000; }
    @media (max-width: 1199px) {
      .logos .logos-bottom {
        padding-bottom: 30px;
        border-bottom: 0; } }
    @media (max-width: 767px) {
      .logos .logos-bottom {
        padding-bottom: 10px; } }
    .logos .logos-bottom__row {
      margin: 0 auto; }
    .logos .logos-bottom__img {
      margin: 0 auto;
      max-height: 78px; }
      @media (max-width: 767px) {
        .logos .logos-bottom__img {
          margin-bottom: 10px; } }

.footer {
  padding: 45px 0;
  padding-bottom: 0;
  background: #000; }
  @media (max-width: 767px) {
    .footer {
      padding: 25px 0; } }
  .footer__container {
    padding: 0; }
    @media (max-width: 1199px) {
      .footer__container {
        padding: 0 30px;
        padding-bottom: 30px; } }
    @media (max-width: 767px) {
      .footer__container {
        padding: 0 20px; } }
  .footer__text {
    max-width: 791px;
    color: #fff;
    font-size: 0.750em;
    line-height: 120%; }
    @media (max-width: 767px) {
      .footer__text {
        font-size: 0.688em; } }
  .footer__copy {
    color: #fff;
    font-size: 0.750em;
    text-align: right; }
    @media (max-width: 1199px) {
      .footer__copy {
        text-align: left;
        margin-top: 30px; } }
  .footer__policy {
    text-align: right;
    margin-top: 20px; }
    @media (max-width: 1199px) {
      .footer__policy {
        text-align: left; } }
  .footer__realization {
    color: #fff;
    font-size: 0.688em;
    text-align: right;
    margin-top: 20px; }
    @media (max-width: 1199px) {
      .footer__realization {
        text-align: left; } }
  .footer__link {
    display: block;
    font-size: 0.750em;
    text-align: right; }
    @media (max-width: 1199px) {
      .footer__link {
        text-align: left; } }
    .footer__link:hover, .footer__link:focus {
      text-decoration: none;
      color: #fff; }
    .footer__link--policy {
      display: inline-block;
      padding: 0 8px;
      border-right: 1px solid #fff; }
      .footer__link--policy:last-child {
        padding-right: 0;
        border: 0; }
      @media (max-width: 1199px) {
        .footer__link--policy:first-child {
          padding-left: 0; } }

.fbWidget {
  width: 392px;
  position: fixed;
  right: -341px;
  z-index: 100;
  top: 450px;
  cursor: pointer;
  transition: right 0.3s; }
  @media (max-width: 767px) {
    .fbWidget {
      display: none; } }
  @media (max-width: 1399px) {
    .fbWidget {
      top: 330px; }
      .fbWidget span {
        height: 400px !important; }
      .fbWidget iframe {
        height: 400px !important; } }
  @media (max-width: 1299px) {
    .fbWidget {
      top: 400px; } }
  .fbWidget:hover {
    right: 0; }
  .fbWidget__button {
    width: 51px;
    height: 208px;
    background: url("/media/default/fb-button.png") no-repeat;
    float: left; }
    @media (max-width: 1299px) {
      .fbWidget__button {
        transform: scale(0.74);
        -webkit-transform: scale(0.74);
        -moz-transform: scale(0.74);
        -ms-transform: scale(0.74);
        -o-transform: scale(0.74);
        position: relative;
        top: -27px;
        right: -7px; } }
  .fbWidget__container {
    float: left;
    border: 1px solid #3B5999;
    border-right: 0; }

.fb-link {
  display: none; }
  @media (max-width: 767px) {
    .fb-link {
      display: block;
      margin: 5px auto;
      width: 300px; } }

.form__label {
  font-size: 1em;
  color: #000 !important; }

.form__input {
  margin-bottom: 10px;
  font-size: 1em;
  border: 1px solid #000; }
  .form__input:focus {
    box-shadow: none;
    border-color: #000; }

.form__textarea {
  margin-bottom: 10px;
  font-size: 1em;
  border: 1px solid #000; }
  .form__textarea:focus {
    box-shadow: none;
    border-color: #000; }

.form__button {
  font-size: 0.875em;
  color: #fff;
  text-align: center;
  font-weight: bold;
  border: 1px solid #fff;
  background: #000;
  width: 100px;
  padding: 10px 0;
  transition: background 0.3s, color 0.3s;
  -webkit-transition: background 0.3s, color 0.3s;
  -moz-transition: background 0.3s, color 0.3s;
  -ms-transition: background 0.3s, color 0.3s;
  -o-transition: background 0.3s, color 0.3s;
  margin-right: 20px;
  outline: 0; }
  .form__button:hover {
    text-decoration: none;
    background: #fff;
    color: #000;
    border-color: #000; }

.alert {
  font-size: 1em; }

.aboutUs-map {
  float: right;
  margin-top: -185px;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  margin-right: -35px; }
  @media (max-width: 1500px) {
    .aboutUs-map {
      margin-right: 0; } }
  @media (max-width: 1199px) {
    .aboutUs-map {
      display: none; } }

.aboutUs__content {
  font-size: 1em; }

.aboutUs-places {
  margin-top: 50px; }
  .aboutUs-places ol li, .aboutUs-places ul li {
    margin-bottom: 10px; }
  @media (max-width: 767px) {
    .aboutUs-places {
      margin-top: 30px; } }
  .aboutUs-places__header {
    font-size: 1.7em;
    text-transform: uppercase;
    font-weight: 300; }
    @media (max-width: 767px) {
      .aboutUs-places__header {
        font-size: 1.9em; } }
  .aboutUs-places__ol {
    text-align: left;
    padding: 0;
    margin: 0;
    margin-left: 15px; }
    @media (max-width: 1199px) {
      .aboutUs-places__ol {
        list-style-type: none;
        margin: 0; } }
  .aboutUs-places__li {
    border-bottom: 1px solid #000;
    padding: 15px 0; }
    @media (max-width: 1199px) {
      .aboutUs-places__li {
        padding-bottom: 20px; } }
    .aboutUs-places__li.active .aboutUs-places__content {
      display: block; }
  .aboutUs-places__a {
    font-size: 1.2em;
    text-transform: uppercase;
    color: #000 !important;
    line-height: 120%; }
    .aboutUs-places__a:hover, .aboutUs-places__a:focus {
      text-decoration: none; }
    .aboutUs-places__a:hover {
      font-weight: bold; }
  .aboutUs-places__content {
    display: none;
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
    border-top: 1px solid #000; }
    @media (max-width: 1199px) {
      .aboutUs-places__content {
        padding-top: 20px;
        margin-bottom: 15px; } }

.aboutUs__title {
  font-weight: bold;
  color: #000 !important;
  text-transform: uppercase;
  font-size: 2.375em;
  margin-top: 36px; }
  @media (max-width: 1199px) {
    .aboutUs__title {
      font-size: 2.1em; } }

.aboutUs__content {
  margin-top: 45px; }
  @media (max-width: 1199px) {
    .aboutUs__content {
      margin-top: 35px; } }
  @media (max-width: 767px) {
    .aboutUs__content {
      margin-top: 15px; } }

.aboutUs-gallery {
  margin-top: 40px; }
  @media (max-width: 1199px) {
    .aboutUs-gallery {
      margin-top: 50px; } }
  @media (max-width: 767px) {
    .aboutUs-gallery {
      margin-right: -15px;
      margin-left: -15px;
      margin-top: 30px; } }

.projectsPage {
  display: block;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #000; }
  .projectsPage__link {
    display: block;
    font-size: 1.25em;
    margin-bottom: 30px;
    line-height: 120%; }
    @media (max-width: 768px) {
      .projectsPage__link {
        font-size: 1.1em;
        margin-bottom: 15px; } }
    .projectsPage__link:hover, .projectsPage__link:focus {
      text-decoration: none; }
  .projectsPage__img {
    max-width: 440px; }
    @media (max-width: 767px) {
      .projectsPage__img {
        max-width: 100%; } }

.promotion {
  margin-top: 20px; }
  .promotion__header {
    font-size: 1.2em; }
  .promotion__ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 15px; }
  .promotion__li {
    line-height: 120%;
    padding: 15px 0;
    border-bottom: 1px solid #000; }
    .promotion__li.active {
      border-bottom: 0; }
      .promotion__li.active .promotion-logos {
        display: block;
        border-top: 1px solid #000;
        padding-top: 15px;
        margin-bottom: 0; }
  .promotion__a {
    font-size: 1.1em; }
    .promotion__a:hover {
      text-decoration: none;
      font-weight: bold; }
    .promotion__a:focus {
      text-decoration: none; }
  .promotion .promotion-logos {
    margin: 15px 0;
    display: none; }
    .promotion .promotion-logos__text-link {
      display: block;
      margin-bottom: 15px;
      font-size: 0.9em; }
    .promotion .promotion-logos__text {
      font-weight: bold;
      font-size: 1em;
      margin-bottom: 5px; }
    .promotion .promotion-logos__link {
      display: inline-block;
      margin: 10px; }
      @media (max-width: 767px) {
        .promotion .promotion-logos__link {
          margin: 5px; } }
    @media (max-width: 767px) {
      .promotion .promotion-logos__img {
        max-height: 80px; } }

.jobOffers-list {
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px dashed #000;
  margin-bottom: 30px; }
  .jobOffers-list:hover, .jobOffers-list:focus {
    text-decoration: none; }
  .jobOffers-list__title {
    font-size: 1.2em;
    line-height: 120%;
    color: #000;
    font-weight: bold; }
  .jobOffers-list__date {
    font-size: 1em;
    font-style: italic;
    text-align: right;
    margin-top: 10px; }

.recruitments {
  margin-bottom: 40px; }
  .recruitments__link {
    display: block;
    border-bottom: 1px solid #000;
    margin-bottom: 20px; }
    .recruitments__link:hover, .recruitments__link:focus {
      text-decoration: none; }
    .recruitments__link:hover {
      color: #000; }
  .recruitments__title {
    font-size: 1.1em;
    font-weight: bold;
    line-height: 130%; }
  .recruitments__content {
    display: none; }

.gatherings__link {
  font-weight: bold;
  color: #000 !important;
  padding: 10px 0;
  display: block;
  border-bottom: 1px solid #000;
  font-size: 1.2em; }
  .gatherings__link:hover, .gatherings__link:focus {
    text-decoration: none;
    color: #000; }

.gatherings .gatherings-block {
  padding: 10px 0;
  border-bottom: 1px dashed #000;
  margin-bottom: 20px; }
  .gatherings .gatherings-block__title {
    font-size: 1.2em;
    margin: 0;
    font-weight: bold; }
  .gatherings .gatherings-block__date {
    font-size: 0.8em;
    font-style: italic;
    text-align: right;
    margin: 10px 0; }
  .gatherings .gatherings-block__desc {
    font-style: italic; }
  .gatherings .gatherings-block__button {
    color: #000;
    display: block;
    margin: 10px;
    font-style: italic;
    text-align: right;
    outline: 0; }
    .gatherings .gatherings-block__button:hover, .gatherings .gatherings-block__button:focus {
      color: #000; }
