.footer{
	padding:45px 0;
	padding-bottom:0;
	background:$color1;

	@media (max-width:$screen-sm - 1px){
		padding:25px 0;
	}

	&__container{
		padding:0;

		@media (max-width:$screen-md - 1px){
			padding:0 30px;
			padding-bottom:30px;
		}

		@media (max-width:$screen-sm - 1px){
			padding:0 20px;
		}
	}

	&__text{
		max-width: 791px;
		color:$color16;
		font-size:0.750em;
		line-height: 120%;

		@media (max-width:$screen-sm - 1px){
			font-size:0.688em;
		}
	}

	&__copy{
		color:$white;
		font-size:0.750em;
		text-align: right;

		@media (max-width:$screen-md - 1px){
			text-align: left;
			margin-top:30px;
		}
	}

	&__policy{
		text-align: right;
		margin-top:20px;

		@media (max-width:$screen-md - 1px){
			text-align: left;
		}
	}

	&__realization{
		color:$white;
		font-size:0.688em;
		text-align: right;
		margin-top:20px;

		@media (max-width:$screen-md - 1px){
			text-align: left;
		}

	}

	&__link{
		display:block;
		font-size: 0.750em;
		text-align: right;

		@media (max-width:$screen-md - 1px){
			text-align: left;
		}

		&:hover,&:focus{
			text-decoration: none;
			color:$white;
		}

		&--policy{
			display:inline-block;
			padding:0 8px;
			border-right:1px solid $white;

			&:last-child{
				padding-right:0;
				border:0;
			}

			@media (max-width:$screen-md - 1px){
				&:first-child{
					padding-left:0;
				}
			}
		}
	}


}