.projects {
    background: $color9;
    padding: 45px 0;
    padding-bottom: 55px;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);

    @media (max-width:$screen-md - 1px) {
        padding-bottom: 50px;
    }

    @media (max-width:$screen-sm - 1px) {
        padding-top: 25px;
    }



    &__container {
        padding: 0;

        @media (max-width:$screen-md - 1px) {
            padding-left: 30px;
            padding-right: 30px;
            max-width: 768px;
            width: auto;
        }

        @media (max-width:$screen-sm - 1px) {
            padding-right: 10px;
            padding-left: 10px;
        }

    }

    &__header {
        max-width: 523px;
        border-bottom: 2px solid $color11;

        &.otherColor {
            border-color: $color5;
        }

        @media (max-width:$screen-sm - 1px) {
            max-width: 292px;
        }
    }

    &__title {
        margin: 0;
        padding: 0;
        font-size: 2.813em;
        color: $color10;
        line-height: 120%;
        letter-spacing: 1px;
        margin-bottom: 10px;

        @media (max-width:$screen-sm - 1px) {
            font-size: 1.875em;
        }

    }

    &__years {
        color: $color10;
        font-size: 2.125em;
        font-weight: 300;
        line-height: 120%;
        margin-bottom: 10px;
        letter-spacing: 1px;

        @media (max-width:$screen-sm - 1px) {
            display: none;
        }
    }

    &__text {
        font-size: 0.938em;
        color: $color10;
        line-height: 120%;
        margin-top: 50px;

        &--bold {
            font-weight: bold;
            display: block;
        }

        @media (max-width:$screen-sm - 1px) {
            font-size: 0.813em;
            margin-top: 15px;
        }
    }

    &__empty {
        margin: 10px 0;
    }




    .projects-content {
        &__header {
            color: $color10;
            font-size: 1.625em;
            line-height: 120%;
            letter-spacing: 0.5px;

            @media (max-width:$screen-sm - 1px) {
                font-size: 1.250em;
                max-width: 232px;
            }
        }

        &__city {
            color: $color10;
            font-size: 1.625em;
            font-weight: bold;
            line-height: 120%;
            text-transform: uppercase;
            margin-top: 10px;
        }

    }


    .project-tabs {

        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-top: 26px;

        @media (max-width:$screen-sm - 1px) {
            margin: 0;
        }

        &--dropdown {
            display: none;
            margin: 0;

            .project-tabs__li {
                border-bottom: 1px dashed $color3;
            }

            .project-tabs__a {
                font-size: 1em;
            }

        }

        &__li {
            padding: 20px 0;
            border-bottom: 1px solid $color3;

            &.active {
                border-bottom: 0;

                .project-tabs--dropdown {
                    display: block;

                    .project-tabs__a {
                        font-style: italic;
                        font-weight: 400;
                    }
                }

                .project-tabs__a {
                    font-weight: bold;
                }
            }
        }

        &__a {
            font-size: 1.125em;
            color: $color10;
            letter-spacing: 0.5px;

            &:hover,
            &:focus {
                text-decoration: none;
                font-weight: bold;
            }


        }
    }



    .about-city {
        margin-top: 40px;

        @media (max-width:$screen-md - 1px) {
            max-width: 568px;
            margin-left: auto;
            margin-right: auto;
        }

        &__text {
            font-size: 0.750em;
            color: $color10;
            line-height: 120%;
            float: left;
            width: 70%;

            @media (max-width:$screen-sm - 1px) {
                float: none;
                width: 100%;
            }

        }

        &__arms {
            float: right;
            margin-top: -7px;

            @media(max-width:$screen-sm - 1px) {
                float: none;
                width: 100%;
                margin-top: 15px;
            }
        }

        &__arms-img {
            @media (max-width:$screen-sm - 1px) {
                max-height: 103px;
                margin: 0 auto;
            }
        }

        &__photo {
            margin-top: 20px;


        }
    }
}



.projects-map {
    margin-top: -11px;
    margin-left: 12px;
    max-width: 358px;

    &__title {
        color: $color10;
        font-size: 0.938em;
        line-height: 120%;
        letter-spacing: 0.3px;

        &--about {
            font-size: 1.25em;
            margin-bottom: 5px;
        }
    }

    &__area {
        outline: none;
    }

    .map-block {
        margin-top: 30px;
        position: relative;

        &--about {
            margin-top: 10px;
        }

        &__city {
            font-size: 0.938em;
            color: $white;
            text-transform: uppercase;
            text-align: center;
            min-width: 144px;
            padding: 12px 6px;
            background: $color4;
            border: 1px solid $white;
            position: absolute;
            right: 80px;
            top: 110px;
            transition: top 0.3s, right 0.3s;

            &--about {
                font-size: 1.1em;
            }

            &.otherColor {
                background: $color5;

                &::after {
                    border-top: 10px solid $color5;
                    border-left: 5px solid $color5;
                }
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: -20px;
                left: 32px;
                border-top: 10px solid $color4;
                border-right: 5px solid transparent;
                border-left: 5px solid $color4;
                border-bottom: 10px solid transparent;

            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                bottom: -23px;
                left: 31px;
                border-top: 11px solid $white;
                border-right: 6px solid transparent;
                border-left: 6px solid $white;
                border-bottom: 11px solid transparent;

            }
        }
    }
}



.chooseCommune {
    margin-top: 35px;

    &--about {
        margin-top: -10px;
    }


    &__header {
        color: $color4;
        line-height: 120%;
        font-size: 1.063em;
        margin-top: 10px;
        letter-spacing: 0.1px;

        &.otherColor {
            color: $color5;
        }

        @media (max-width:$screen-md - 1px) {
            &--desktop {
                display: none;
            }
        }

        @media (max-width:$screen-sm - 1px) {
            font-size: 1.063em;



        }

        &--mobile {
            display: none;

            @media (max-width:$screen-md - 1px) {
                display: block;
            }
        }




        &--big {
            font-size: 2.063em;
            text-transform: uppercase;
            letter-spacing: 0.8px;
            margin: 0;

            @media (max-width:$screen-sm - 1px) {
                font-size: 1.688em;
            }
        }

        &--about {
            font-size: 1.163em;
        }

        &--about-big {
            font-size: 2.2em;
        }
    }


    .chooseCommune-list {
        width: 397px;

        &__select {
            width: 397px;
            background-color: $white;
            border: 1px solid $color12;
            height: 41px;
            font-size: 0.938em;
            color: $color7;
            padding: 0 6px;
            padding-left: 10px;
            margin-top: 20px;

            position: relative;
            background-image: url('/media/default/select-arrow.jpg');
            background-position: calc(100% - 8px) calc(1em - 6px), calc(100% - 15px) calc(1em + 2px), 100% 0;
            background-repeat: no-repeat;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;



            @media (max-width:$screen-sm - 1px) {
                width: 293px;
            }

            &--about {
                font-size: 1em;
            }

        }

        &__option {
            font-size: 0.938em;

            &--about {
                font-size: 1em;
            }
        }
    }

    &__choice {
        font-size: 15px;
        color: $color10;
        margin-top: 33px;

        @media (max-width:$screen-sm - 1px) {
            margin-top: 25px;
        }

        &--commune {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.125em;
            margin-left: 15px;
            letter-spacing: 1.1px;
        }
    }
}


.projectsList {
    margin-bottom: 20px;

    &__link {
        display: block;
        border-bottom: 1px solid $color3;
        margin-bottom: 20px;

        &:hover,
        &:focus {
            text-decoration: none;

        }

        &:hover {
            color: $color4;
        }
    }

    &__title {
        font-size: 1.1em;
        font-weight: bold;
        line-height: 130%;
    }

    &__content {
        display: none;
    }
}