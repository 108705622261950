html,
body {
    font-size: 1em;
    font-family: 'Oxygen', sans-serif;
    font-weight: 400;
    line-height: 120%;
    background: $color1;
    overflow-x: hidden;


    a,
    button,
    select,
    input {
        &:focus {
            outline: 2px solid #ff0;
            outline-offset: 2px;
        }
    }

    *[role="button"] {
        &:focus {
            outline: 2px solid #ff0;
            outline-offset: 2px;
        }
    }


    &.using-mouse {

        a,
        button {
            &:focus {
                outline: 0 !important;
            }
        }

        *[role="button"] {
            &:focus {
                outline: 0 !important;
            }
        }

    }

}

.hidden-element {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.normal_f {
    font-size: 1em
}

.medium_f {
    font-size: 1.125em
}

.big_f {
    font-size: 1.25em
}


.clear {
    clear: both;
}

.content {
    position: relative;
    top: -45px;
    background: $white;

    @media (max-width: $screen-md - 1px) {
        position: static;
    }
}

.hr,
hr {
    border: 0.5px solid $color13;
    margin-top: 35px;
    margin-bottom: 30px;

    @media (max-width: $screen-sm - 1px) {
        margin-top: 25px;
        margin-bottom: 20px;
    }
}

h1 {
    font-size: 2.813em;
}

h2 {
    font-size: 2.063em;
}

h3 {
    font-size: 1.250em;
}

h4 {
    font-size: 1.125em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 0.875em;
}

.button {
    display: block;
    font-size: 0.875em;
    color: $white;
    text-align: center;
    font-weight: bold;
    border: 1px solid $white;
    background: $color4;
    max-width: 267px;
    padding: 20px 0;
    margin: 0 auto;
    position: relative;
    left: -142px;
    margin-top: 35px;
    transition: background 0.3s, color 0.3s;
    -webkit-transition: background 0.3s, color 0.3s;
    -moz-transition: background 0.3s, color 0.3s;
    -ms-transition: background 0.3s, color 0.3s;
    -o-transition: background 0.3s, color 0.3s;

    &.otherColor {
        background: $color5;

        &:hover,
        &:focus {
            color: $color5;
            border-color: $color5;
            background: $white !important;
        }
    }


    @media (max-width: $screen-lg - 1px) {
        position: static;
    }

    @media (max-width: $screen-md - 1px) {
        margin-top: 15px;
    }

    @media (max-width: $screen-sm - 1px) {
        margin-top: 35px;
    }


    &--gallery {
        position: static;
        margin-top: 45px;

        @media (max-width: $screen-md - 1px) {
            margin-top: 20px;
        }
    }

    &--projects {
        position: static;
        margin-top: 20px;
    }


    &:hover,
    &:focus {
        text-decoration: none;
        background: $white;
        color: $color4;
        border-color: $color4;
    }
}

.information-button {
    display: block;
    font-size: 1.313em;
    color: $white;
    text-align: center;
    background: $color15;
    padding: 17px 0;
    width: 766px;
    margin: 20px auto;
    line-height: 120%;
    margin-bottom: 45px;

    @media (max-width: $screen-md - 1px) {
        display: none;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: $white;
    }

    &__arrow {
        display: inline-block;
        width: 12px;
        height: 13px;
        background: url('/media/default/button-arrow.png') no-repeat;
        position: relative;
        left: 5px;
    }
}

.pagination {
    .disabled {
        display: none;
    }

    span {

        &:hover,
        &:focus {
            background: $color20 !important;
        }
    }

    .active {
        span {
            background: $color4;
            font-size: 1em;
            display: block;
            padding: 5px 15px;

        }

        a {
            padding: 5px 12px !important;
        }
    }

    a {
        font-size: 1em;
        color: $color10 !important;
        padding: 5px 12px !important;

        &:hover,
        &:focus {
            background: $white !important;
        }
    }
}

.skipLinks {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    &__link {
        display: block;
        font-size: 1.5em;
        color: $color18;
        background: $color7;
        text-decoration: none;
        padding: 20px;
        text-align: center;
        width: 300px;
        position: absolute;
        left: -999em;

        &:focus {
            text-decoration: none;
            color: $color18;
            left: 0;
        }
    }
}

table {

    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
    display: block;


    tr {
        td {
            border: 1px solid $color10;
            padding: 5px;
        }
    }
}

.arrows {
    display: inline-block;
    background: url('/media/default/arrows.png') no-repeat;
    width: 12px;
    height: 12px;
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.questionnaire {
    display: block;
    background: $color21;
    color: $white;
    font-size: 24px;
    padding: 15px 20px;
    padding-bottom: 25px;
    border-radius: 5px;
    position: fixed;
    font-weight: bold;
    right: -95px;
    top: 300px;
    z-index: 100;
    transform: rotate(-90deg);

    @media (max-width: $screen-lg - 1px) {
        top: 180px;
    }

    @media (max-width: 1299px) {
        font-size: 20px;
        right: -85px;
        top: 275px;
        padding: 10px 20px;
        padding-bottom: 15px;
    }

    @media (max-width: $screen-sm - 1px) {
        position: static;
        transform: none;
        max-width: 300px;
        margin: 5px auto;
        border-radius: 0;
        text-align: center;

    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: $white;
    }
}

.list {
    &__ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &__li {
        border-bottom: 1px solid $color3;
        padding: 15px 0;
        margin-bottom: 15px;

        &.active {
            .list__content {
                display: block;
            }
        }
    }

    &__a {
        font-size: 1.1em;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:hover {
            font-weight: bold;
        }
    }

    &__content {
        display: none;
        margin-top: 15px;

        a {

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

.sitemap {
    &__ul {
        list-style-type: none;
        font-size: 1.3em;

        @media (max-width: $screen-md - 1px) {
            margin: 0;
            padding: 0;
        }

        li {
            font-weight: bold;
        }

        &--next {
            @media (max-width: $screen-md - 1px) {
                margin-left: 30px;
            }

            li {
                font-weight: 400;
            }

            a {
                font-size: 0.7em;
            }
        }
    }

    &__li {
        line-height: 120%;
        margin-bottom: 10px;
    }


    .dropdown-menu {
        background: none !important;
        margin: 0;
        border: 0;
        color: $color10;
        font-size: 1em;
        display: block;

        .menu-tabs__a {
            color: $color10;

            &:hover {
                text-decoration: underline;
            }
        }


    }
}

.breadcrumbs {
    padding: 15px 35px;
    padding-bottom: 0;
    margin-bottom: -20px;


    @media (max-width: $screen-sm - 1px) {
        padding: 15px 15px;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;
        align-items: center;

        &:last-child {
            span {
                display: none;
            }

            a {
                font-weight: bold;
            }
        }

        span {
            margin: 0 10px;
        }
    }

    a {
        color: #333;
        font-size: 0.8em;
        text-transform: uppercase;
        display: block;
        text-decoration: none;
        cursor: default;

        &:hover:not([href="#"]) {
            font-weight: bold;
            cursor: pointer;
        }

        &::first-letter {
            text-transform: uppercase;
        }
    }
}