.recruitments{
	margin-bottom:40px;

	&__link{
	display:block;
	border-bottom:1px solid $color3;
	margin-bottom:20px;
			&:hover,&:focus{
				text-decoration: none;
				
			}

			&:hover{
				color:$color4;
			}
	}

	&__title{
		font-size:1.1em;
		font-weight:bold;
		line-height: 130%;
	}

	&__content{
		display:none;
	}
}