.news{
	background:url('/media/default/news-bg.jpg')no-repeat;
	background-size:cover;
	padding: 36px 35px;
	padding-bottom:52px;
	height:100%;

	@media (max-width:$screen-md - 1px){
		padding-left:20px;
		padding-right:20px;
		padding-bottom:30px;
	}

	@media (max-width:$screen-sm - 1px){
		padding-top:20px;
	}



	&__header{
		margin:0;
		padding:0;
		font-size:2.813em;
		color:$white;
		margin-bottom:40px;

		@media (max-width:$screen-md - 1px){
			margin-bottom:20px;
			position:relative;
			left:-70px;
		}

		@media (max-width:$screen-sm - 1px){
			font-size:1.875em;
			position:static;
			text-align: center;
		}
	}

	.news-container{
		max-width:870px;
		
		@media (max-width:$screen-md - 1px){
			max-width: 568px;
			margin:0 auto;

		}

		@media (max-width:$screen-sm - 1px){
			max-width: 291px;
		}
	}

	.news-block{
		max-width:568px;
		background:$white;
		padding:12px;
		padding-bottom:50px;
		display:block;
		min-height: 568px;

				@media (max-width:$screen-lg - 1px){
					min-height:495px;
				}

				@media (max-width:$screen-md - 1px){
					min-height: 568px;
					margin:0 auto;
					margin-bottom:40px;
				}

				@media (max-width:$screen-sm - 1px){
					padding:7px;
					min-height: 424px;
					margin-bottom:20px;
				}



				&:hover,&:focus{
					text-decoration: none;
				}

				&--next{
					margin-left:20px;
					padding-bottom:32px;
					min-height: 267px;

						@media (max-width:$screen-lg - 1px){
							min-height:230px;
						}

						@media (max-width:$screen-md - 1px){
							max-width:267px;
							min-height: 267px;
						}

						@media (max-width:$screen-sm - 1px){
							margin:0 auto;
							padding:10px;
						}

				}

				&--top{
					margin-bottom:32px;
					min-height: 267px;
						@media (max-width:$screen-lg - 1px){
							min-height:230px;
						}

						@media (max-width:$screen-md - 1px){
							max-width:267px;
							min-height:267px;
							margin-left:0;
						}
						
						@media (max-width:$screen-sm - 1px){
							margin:0 auto;
							margin-bottom: 20px;
						}
				}
		
			&__title{
			font-size:1.250em;
			color:$color7;
			font-weight: bold;
			margin:0;
			padding:0;
			margin-top:12px;

			@media (max-width:$screen-sm - 1px){
				font-size:1.125em;
			}

					&--next{
						font-size: 1em;
						margin-top:15px;

					}
			}

			&__date{
				color:$color7;
				font-size: 0.813em;
				margin-top:5px;

				&--next{
					font-size:0.750em
				}
			}

			&__description{
				color:$color7;
				font-size:0.875em;
				margin-top:9px;
				max-width:540px;

				@media (max-width:$screen-sm - 1px){
					font-size: 0.813em;
				}
			}

			&__img{
				width:100%;
				max-width:auto;
				max-height:363px;

				&--next{
					max-height: 159px;
				}
			}
	}



}

.news-list{
	margin-bottom:30px;
	padding-bottom:30px;
	display:block;
	color:$color10;
	border-bottom:1px solid $color8;




	@media (max-width:$screen-sm - 1px){
		max-width:260px;
		margin:0 auto;
		margin-bottom:30px;
	}

			&:hover,&:focus{
				text-decoration: none;
				color:$color10;

				.news-list__button{
					text-decoration: none;
    				background:$white;
    				color:$color4;
    				border-color:$color4;
				}
			}

	&__col{
		@media (max-width:$screen-sm - 1px){
			padding:0;
		}
	}

	&__img{
		max-width: auto;
		width:100%;
		max-height: 170px;

		@media (max-width:$screen-sm - 1px){
			margin-bottom:10px;
		}

	}

	&__title{
		font-weight:bold;
		margin:0;
		font-size:1.25em;
		margin-bottom:5px;

	}

	&__date{
		font-size:0.85em;
		margin-bottom:10px;
	}

	&__desc{
		font-size:1em;
	}

	&__button{
		display:block;
		text-align: center;
		border:1px solid $white;
		background:$color4;
		color:$white;
		font-size:0.9em;
		width:170px;
		padding:10px 0;
		float:right;
		margin-top:10px;
   		transition:background 0.3s, color 0.3s;
   		-webkit-transition:background 0.3s, color 0.3s;
   		-moz-transition:background 0.3s, color 0.3s;
   		-ms-transition:background 0.3s, color 0.3s;
   		-o-transition:background 0.3s, color 0.3s;			
	}
}