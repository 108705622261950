.jobOffers-list{
	display:block;
	padding-bottom:20px;
	border-bottom:1px dashed $color3;
	margin-bottom:30px;

	&:hover,&:focus{
		text-decoration:none;
	}

	&__title{
		font-size:1.2em;
		line-height:120%;
		color:$color3;
		font-weight:bold;
	}

	&__date{
		font-size:1em;
		font-style:italic;
		text-align: right;
		margin-top:10px;
	}

}