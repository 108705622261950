.page{
	background:$white;
	box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.2);
	-webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.2);
	-ms-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.2);
	-o-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.2);
	position:relative;
	display:flex;

	@media (max-width:$screen-md - 1px){
		display: block;
	}

	
	
	&__column{
		float:left;

		@media (max-width:$screen-md - 1px){
			float:none;
		}

		&--left{
			width:32.33%;
			padding:0 15px;
			padding-bottom:30px;

			@media (max-width:1499px){
				width:auto;
			}

			@media (max-width:$screen-md - 1px){
				width:100%;
				display:none;
			}
			
		}

		&--right{
			width:67.6666666667%;
			background:$color9;

			@media (max-width:1499px){
				width:100%;
			}

			@media (max-width:$screen-md - 1px){
				width:100%;
			}
		}
	}
}


.page-baner-link{
	display:block;
	margin-top:30px;
}

.subpage{
	padding: 36px 35px;
    padding-bottom: 52px;

    @media (max-width:$screen-sm - 1px){
    	padding:20px 15px;
    }

    .subpage-container{
    	max-width:870px;

    	&--news-list{
    		@media (max-width:$screen-sm - 1px){
    			max-width: 320px;
    			margin:0 auto;
    		}
    	}
    }

    &__title{
    	color:$color3;
    	font-size:2.813em;
    	line-height: 120%;
    	margin:0;
    	padding:0;
    	width: 525px;
    	padding-bottom: 8px;
    	border-bottom: 2px solid $color11;
    	margin-bottom:40px;


    			&.otherColor{
    				border-color:$color5;
    			}

    	@media (max-width:$screen-sm - 1px){
    		font-size:1.875em;
    		max-width: 292px;
    		margin-bottom: 20px;
    	}


				&--news{
					width:100%;
				}

				&--jobOffers{
					font-size:1.2em;
					color:$color4;
					border:0;
					margin-bottom:0;
					width:100%;
				}

				&--gatherings{
					width:100%;
					text-transform: uppercase;
				}
    }

    &__date{
    	font-size:0.9em;
		font-style:italic;
		text-align: right;
		color:$color10;
		padding-bottom:20px;
		border-bottom:2px solid $color4;
		margin-bottom:40px;
		margin-top:20px;
    }

    &__content{
    	color:$color10;
    	font-size: 0.938em;
    	line-height: 120%;
						
				
						a{
							color:$color10;
						}
					


    	@media (max-width:$screen-sm - 1px){
    		font-size: 0.813em;
    	}
    }

    &__back{
    	font-size:1em;
    	color:$color4 !important;
    	text-align: right;
    	display:block;
    	margin:20px 0;
    }
}

.page-gallery{
	margin-top:40px;

	&__link{
		display:inline-block;
		margin:10px;

		@media (max-width:$screen-sm - 1px){
			max-width:140px;
		}
	}
}

.page-file{
	&__title{
		font-size:1.2em;
		font-weight: bold;
		color:$color10;
		margin-bottom:10px;
		margin-top:30px;
	}

	.file{
		margin-bottom:10px;
		a{
			color:$color10;
			font-size:1em;
			&:hover,&:focus{
				color:$color10;
				text-decoration: none;
			}
		}

		&__img{
			width:30px;
		}
	}
}


.page-result-text{
	font-size:1.25em;
	margin-bottom:15px;
}

.page-result-link{
	font-size:1.25em;
	display:block;
	text-decoration:none;
	color:$color4 !important;
	line-height:120%;
	margin-bottom:20px;

	&:hover{
		color:$color4;
	}

}