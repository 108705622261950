.aboutUs-map{
	float:right;
    margin-top: -185px;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    margin-right:-35px;

    @media (max-width:1500px){
    	margin-right:0;
    }

    @media (max-width:$screen-md - 1px){
    	display:none;
    }
}

.aboutUs__content{
	font-size:1em;
}

.aboutUs-places{
	margin-top:50px;
	ol,ul{
	li{
		margin-bottom:10px;
	}
}

	@media (max-width:$screen-sm - 1px){
		margin-top:30px;
	}

	&__header{
		font-size:1.7em;
		text-transform: uppercase;
		font-weight: 300;

		@media (max-width:$screen-sm - 1px){
			font-size:1.9em;
		}
	}

	&__ol{
		text-align: left;
		padding:0;
		margin:0;
		margin-left:15px;

		@media (max-width:$screen-md - 1px){
			list-style-type: none;
			margin:0;
		}
	}

	&__li{
		border-bottom: 1px solid $color3;
		padding:15px 0;

				@media (max-width:$screen-md - 1px){
					padding-bottom:20px;
				}

			&.active{
				.aboutUs-places__content{
					display:block;
				}
			}
	}

	&__a{
		font-size:1.2em;
		text-transform: uppercase;
		color:$color10;
		line-height:120%;
				

		&:hover,&:focus{
			text-decoration: none;

		}

		&:hover{
			font-weight: bold;
		}
	}

	&__content{
		display:none;
		padding-top:15px;
		margin-top:15px;
		margin-bottom:30px;
		border-top:1px solid $color3;

		@media (max-width:$screen-md - 1px){
			padding-top:20px;
			margin-bottom:15px;
		}
	}
}

.aboutUs{
	&__title{
		font-weight: bold;
		color:$color10;
		text-transform: uppercase;
		font-size: 2.375em;
    	margin-top: 36px;

    	@media (max-width:$screen-md - 1px){
    		font-size:2.1em;
    	}
	}

	&__content{
		margin-top:45px;	

		@media (max-width:$screen-md - 1px){
			margin-top:35px;
		}

		@media (max-width:$screen-sm - 1px){
			margin-top:15px;
		}
	}
}

.aboutUs-gallery{
	margin-top:40px;

	@media (max-width:$screen-md - 1px){
		margin-top:50px;
	}

	@media (max-width:$screen-sm - 1px){
		margin-right:-15px;
		margin-left:-15px;
		margin-top:30px;
	}
}