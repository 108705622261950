$white:#fff;
$black:#000;
$default-color: $white;

$color1:$black;
$color2:#FAFAFA;
$color3:$black;
$color4:$black;
$color5:$black !important;
$color6:$black !important;
$color7:$black;
$color8:$black;
$color9:$white;
$color10:$black !important;
$color11:$black;
$color12:$black;
$color13:rgba(136, 136, 136, 0.29);
$color14:$black;
$color15:$black;
$color16:$white;
$color17:#3B5999;
$color18:#ff0;
$color19:$black;
$color20:$black;
$color21:$black;
$color22:$black;
$color23:$black;


$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 1200px !default;
$screen-lg: 1400px !default;


a {
    color: #000;
}

.gallery__pause,
.logos__pause {
    &:focus {
        outline: 2px solid #0000ff !important;
        color: #000 !important;
    }
}

[role="tabpanel"]:focus {
    outline: 2px solid #ff0 !important;
    outline-offset: -4px !important;
}

.menu-tabs {

    &__a,
    a {

        &:hover,
        &:focus {
            color: $white !important;
        }
    }

    &__li.active {

        .menu-tabs__a,
        a {
            color: $white !important;
        }
    }
}

.footer__link {
    color: #fff !important;
}



.header {
    a {
        outline-color: #000 !important;
    }
}

.menu-list__name {
    outline-offset: -4px !important;
}

.hidden-element {
    color: #fff !important;
    background: #000 !important
}


.mobile-menu a {
    color: #fff !important;
    background: #000 !important;
}


.menu-tabs__a,
.page-baner-link,
.file>a,
.breadcrumbs a,
.news-list {
    outline-color: #0000FF !important;
}

.chooseCommune-list__select {
    outline-color: #0000FF !important;
}

.menu-tabs__li>a {
    outline-color: #0000FF !important;
}

.news {
    background: $black !important;
    border-top: 2px solid $white;
}

.button {
    transition: none !important;
}



.form__button {
    transition: none !important;
}

.projects .projects-map .map-block__city {
    transition: none !important;
}

.hr {
    border-color: $black !important;
}

.gallery {
    background: $white !important;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    border-right: 0.5px solid $black;

}

.pagination a {
    outline-color: #0000ff !important;
}

.gallery-box {
    outline-color: #0000ff !important;
    outline-offset: -2px !important;
}

.logos-bottom a {
    outline-color: #0000ff !important;
    display: block;
}

.page-gallery__link,
.page-result-link {
    outline-color: #0000ff !important;
}

.gatherings-block__button {
    outline: 2px solid;
    outline-color: #0000ff !important;
}

.button,
.information-button {
    outline-color: #0000ff !important;
}

.gatherings a {
    outline-color: #0000ff !important;
}

.page__column--left {
    border-right: 2px solid $black;
}

.mobile-menu__ul {
    border-bottom: 1px solid $white;

    &:last-child {
        border: 0;
    }
}

.mobile-menu .menu-tabs__li a {
    border-color: $white !important;
}

.alert {
    background: $white;
    border: 1px solid $black;
    color: $black;
}

.questionnaire {
    border: 1px solid $white;
}

.dropdown-menu {
    @media (max-width:$screen-md - 1px) {
        background: #000 !important;
        border-color: $white !important;



    }
}

.sitemap__ul {
    .menu-tabs__a {

        &:hover,
        &:focus {
            color: #000 !important;
        }
    }
}